/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable radix */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import { faChevronRight, faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import facebookImg from "../../assets/Components/Services/fe_facebook.png";
import linkdinImg from "../../assets/Components/Services/uil_linkedin.png";
import eCityLogo from "../../assets/Navbar/ecitylogo.svg";
import { eCityDetailsData } from "../../store/eCityStore";
import ECityDetailsCards from "./ECityDetailsCards/ECityDetailsCards";
import "./ECityDetails.css";
import ReuseableFooterBanner from "../../Components/reuseableComponents/reuseableFooterBanner/ReuseableFooterBanner";
import bannerImage from "../../assets/Components/Ecity/FooterBanner/leftSideBannerImage.png";
import { Helmet } from "react-helmet-async";

function ECityDetails() {
  const [selectedECityDetailsId, setselectedECityDetailsIdId] = useState(1);
  // console.log(selectedECityDetailsId);
  const { id: paramId } = useParams();
  // console.log('paramId = ', paramId);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (paramId) {
      setselectedECityDetailsIdId(parseInt(paramId));
    }
  }, [paramId]);

  const navigate = useNavigate();

  let content = <div>test</div>;

  eCityDetailsData.map((data) => {
    if (data.id === selectedECityDetailsId) {
      // console.log('data = ', data)
      content = (
        <div>
          <Helmet>
            <title>
              {data?.metaTitle
                ? data.metaTitle
                : "SmartMatrix - Providing The Best Services & IT Solutions"}
            </title>
            <meta
              name="description"
              content={
                data?.metaDescription
                  ? data.metaDescription
                  : "SmartMatrix PTE Ltd., based in Singapore, offers innovative, tech-driven solutions for seamless living and efficient industrial operations. As the sole agent, consultant, and distributor for leading global brands in the industrial and IT sectors, we serve the Asian and European markets, providing critical after-sales services. Contact us at info@smartmatrix.com.sg"
              }
            />
          </Helmet>
          <img
            src={data.image}
            alt={data.alt}
            className="w-full max-h-[400px] object-cover"
          />
          <p className="my-6 details-header">{data.headerTitle}</p>
          <div className="service-description">
            {parse(data.headerDescription)}
          </div>
          <div>
            {data.cardGallery && (
              <ECityDetailsCards cardGallery={data.cardGallery} />
            )}
          </div>

          {data.bottomImage && (
            <div className="mt-[60px]">
              <img
                src={data.bottomImage}
                alt=""
                className="w-full max-h-[400px] object-cover"
              />
            </div>
          )}
        </div>
      );
    }
  });
  return (
    <div>
      <Navbar />
      <div className="relative">
        <div className="eCityDetails-hero">
          <div className="container flex flex-col justify-center h-full">
            <div className="flex flex-row heroTitle">E-City Details</div>
            <div className="mt-6 flex flex-row items-center">
              <FontAwesomeIcon icon={faHouse} color="white" />
              <FontAwesomeIcon
                icon={faChevronRight}
                color="white"
                style={{ paddingLeft: "1rem" }}
              />
              <div
                className="text-white"
                style={{
                  paddingLeft: "1rem",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                <div className="flex items-center">
                  <div>
                    <img src={eCityLogo} alt="" className="h-[16px]" />
                  </div>
                  <div className="ml-[5px]">E-City</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-20 container">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
          <div className="col-span-1 gap-6">
            <div className="flex flex-col gap-6">
              {eCityDetailsData.map((data) =>
                selectedECityDetailsId === data.id ? (
                  <div
                    key={data.id}
                    className="flex flex-row cursor-pointer"
                    // onClick={() => setselectedECityDetailsIdId(data.id)}
                    onClick={() => navigate(`/ecitydetails/${data.id}`)}
                  >
                    <div className="px-5 py-4 flex items-center bg-[#2792D0]">
                      <FontAwesomeIcon icon={faChevronRight} color="white" />
                    </div>
                    <div className="px-4 py-4 nav-title bg-[#293742] w-full text-white">
                      <p>{data.title}</p>
                    </div>
                  </div>
                ) : (
                  <div
                    key={data.id}
                    className="flex flex-row cursor-pointer"
                    // onClick={() => setselectedECityDetailsIdId(data.id)}
                    onClick={() => navigate(`/ecitydetails/${data.id}`)}
                  >
                    <div
                      className="px-3 py-4 flex items-center"
                      style={{
                        background: "rgba(39, 146, 208, 0.1)",
                        color: "rgba(133, 142, 150, 1)",
                      }}
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                    <div className="px-4 py-4 nav-title w-full">
                      <p>{data.title}</p>
                    </div>
                  </div>
                )
              )}
              <div className="p-4 follow-section">
                <div className="flex flex-row">
                  <p>Follow Us on</p>
                </div>
                <div className="flex flex-row justify-center items-center h-2/3 gap-6">
                  <div
                    className="p-4 rounded-full"
                    style={{ background: "rgba(44, 46, 51, 0.1)" }}
                  >
                    <a
                      href="https://www.facebook.com/people/SMARTMATRIX-PTE-LTD/100063454840485/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={facebookImg}
                        alt="facebookImg"
                        className="h-[34px]"
                      />
                    </a>
                  </div>
                  <div
                    className="p-4 rounded-full"
                    style={{ background: "rgba(44, 46, 51, 0.1)" }}
                  >
                    <a
                      href="https://www.linkedin.com/company/smartmatrix-pte-ltd/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={linkdinImg}
                        alt="facebookImg"
                        className="h-[34px]"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2">{content}</div>
        </div>
      </div>

      <div className="mt-[45px] lg:mt-[90px]">
        <ReuseableFooterBanner
          bannerImage={bannerImage}
          title="Take advantage of the experience of our experts."
          buttonName="CONTACT US"
          url="/contact"
        />
      </div>
      <Footer />
    </div>
  );
}

export default ECityDetails;
