import React from "react";
import "./MiniReUseableTitle.css";

function MiniReUseableTitle({ title, color }) {
  // const color = '#195CA5'
  // console.log('color ', color);
  return (
    <div className="flex items-center">
      <div className={`miniReUseableTitle text-[${color}]`}>
        {title.toUpperCase()}
      </div>

      <div className={`bg-[${color}] w-[35px] h-[1.5px] ml-[10px]`} />
    </div>
  );
}

export default MiniReUseableTitle;
