/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from "react";
import WhyChooseSmartmatrixImg from "../../../assets/Components/Home/WhyChooseSmartmatrix/WhyChooseSmartmatrixImg.png";
import MiniReUseableTitle from "../MiniReUseableTitle/MiniReUseableTitle";
import LatestTechnologiesItem from "./ListItem/LatestTechnologiesItem";
import PowerfulStrategiesItem from "./ListItem/PowerfulStrategiesItem";
import UniqueSolutionsItem from "./ListItem/UniqueSolutionsItem";
import "./WhyChooseSmartmatrix.css";
import { useNavigate } from "react-router-dom";

function WhyChooseSmartmatrix() {
  const navigate = useNavigate();
  return (
    <div className="container sectionContentMargin1">
      <div className="grid grid-flow-row lg:grid-cols-2 gap-5">
        <div className="order-last lg:order-first">
          <MiniReUseableTitle title="Why Choose Smartmatrix" color="#195CA5" />

          <div className="sectionTitle whyChooseSmartmatrixMargin">
            Consistently Delivering Beyond What You Imagine
          </div>

          <div className="sectionContent whyChooseSmartmatrixMargin">
            Selection SmartMatrix for software development, where global
            recognition meets agile excellence. With a commitment to quality and
            efficient time-to-market, we assure successful outcomes, aligning
            innovation with client needs.
          </div>

          <div className="mt-[20px] flex flex-col gap-[30px]">
            <LatestTechnologiesItem />
            <UniqueSolutionsItem />
            <PowerfulStrategiesItem />
          </div>

          <div className="mt-[60px]">
            <button
              type="button"
              className="buttonPrimary"
              onClick={() => navigate("/contact")}
            >
              GET IN TOUCH
            </button>
          </div>
        </div>

        <div className="flex items-cente">
          <div className="max-h-[546px] flex">
            <img
              src={WhyChooseSmartmatrixImg}
              alt=""
              className="object-scale-down"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyChooseSmartmatrix;
