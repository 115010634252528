/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from "react";
import AboutHeroSection from "../../Components/About/AboutHeroSection";
import Footer from "../../Components/Footer/Footer";
import "../../Components/Home/AboutOurCompany/AboutOurCompany.css";
import MiniReUseableTitle from "../../Components/Home/MiniReUseableTitle/MiniReUseableTitle";
import Navbar from "../../Components/Navbar/Navbar";
import vissionImg from "../../assets/Components/About/Vision.png";
import barcodeImg from "../../assets/Components/About/barcode.webp";
import collegeImg from "../../assets/Components/About/collegeImg.webp";
import computerImg from "../../assets/Components/About/computer.webp";
import designImg from "../../assets/Components/About/design.png";
import missionImg from "../../assets/Components/About/mission.png";
import informedImg from "../../assets/Components/About/outreach.png";
import productImg from "../../assets/Components/About/quality.png";
import softwareImg from "../../assets/Components/About/software.png";
import onlineImg from "../../assets/Components/About/support.png";
import userImg from "../../assets/Components/About/user.png";
import satisfiedClientsImage from "../../assets/Components/Home/AboutOurCompany/satisfiedClientsImage.png";
import "./About.css";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

function About() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          SmartMatrix - Innovative Solutions & Services for Your Creative Ideas
        </title>
        <meta
          name="description"
          content="SmartMatrix offers innovative solutions and services for your creative ideas, led by seasoned professionals in communications and IT."
        />
      </Helmet>

      <Navbar />
      <AboutHeroSection />
      {/** Welcome To SmartMatrix */}
      <div className="mt-20 container grid grid-cols-1 md:grid-cols-2 gap-5">
        <div>
          <MiniReUseableTitle title="WELCOME TO SMARTMATRIX" color="#195CA5" />
          <div className="mt-5 flex flex-row main-title">
            We Provide Solutions & Services For Your Creative Ideas
          </div>
          <p className="mt-3 para-text">
            SmartMatrix is driven by seasoned professionals with extensive
            expertise in communications and information technology. Our team
            includes dynamic business graduates and skilled engineers from
            prestigious global institutions, bringing innovative solutions and a
            results-oriented approach to every project.
          </p>
          <div className="my-5 flex flex-row gap-4">
            <div className="flex flex-col">
              <img src={missionImg} alt="missionImg" />
            </div>
            <div className="flex flex-col">
              <h4 className="aboutOurCompanyCardTitle ">Our Mission</h4>
              <p className="para-text">
                Our mission is to manufactures electronic products and Software
                Development for both domestic and international market by
                continually improving our process, utilizing quality practices,
                employee skill development that result in customer and employee
                satisfaction.
              </p>
            </div>
          </div>
          <hr />
          <div className="mt-5 flex flex-row gap-4">
            <div className="flex flex-col">
              <img src={vissionImg} alt="vissionImg" />
            </div>
            <div className="flex flex-col">
              <h4 className="aboutOurCompanyCardTitle">Our Vision</h4>
              <p className="para-text">
                We will provide competitive, high quality electronic
                manufacturing services, developing software and individualized
                customer service, while encouraging employee creativity,
                motivation and teamwork in a continually improving environment.
                We will solve today's challenges and enable tomorrows
                technology.
              </p>
            </div>
          </div>
          <div className="my-12">
            <button
              type="button"
              className="DiscoverMoreButton inline-block"
              onClick={() => navigate("/contact")}
            >
              GET STARTED
            </button>
          </div>
        </div>
        <div>
          <div className="relative">
            <div className="max-h-[520px] max-w-[382px] ml-52">
              <img
                src={computerImg}
                alt="computerImg"
                className="object-cover"
              />
            </div>
            <div className="max-h-[240px] max-w-[415px] absolute top-[380px] left-24 hidden md:block">
              <img src={barcodeImg} alt="barcodeImg" className="" />
            </div>
            <div className="absolute top-[180px] left-24">
              <div className="inline-block">
                <div
                  className="flex p-[15px] satisfiedClientsContainer gap-3"
                  style={{ borderRadius: "12px 0px" }}
                >
                  <div>
                    <img
                      src={satisfiedClientsImage}
                      alt=""
                      className="h-[52px]"
                    />
                  </div>
                  <div>
                    <div className="satisfiedClientsTitle">3600+</div>
                    <div className="satisfiedClientsContent">
                      Satisfied Clients
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/** Top Reasons */}
      <div className="mt-32 container flex flex-col items-center justify-center ">
        <MiniReUseableTitle title="TOP REASONS" color="#195CA5" />
        <div className="mt-5 flex flex-row main-title">
          What We Promise High Quality IT Agency Services
        </div>
        <div className="my-8 flex flex-wrap gap-6 justify-center">
          <div className="w-[270px] py-4 flex flex-col items-center justify-center border border-2 border-[#195CA5] bg-white text-black service-card">
            <img
              src={designImg}
              alt="designImg"
              className="max-h-[95px] max-w-[95px]"
            />
            <p className="mt-4 text-[25px] font-semibold">Responsive Design</p>
          </div>
          <div className="w-[270px] py-4 flex flex-col items-center justify-center border border-2 border-[#195CA5] bg-white text-black service-card">
            <img
              src={productImg}
              alt="designImg"
              className="max-h-[95px] max-w-[95px]"
            />
            <p className="mt-4 text-[25px] font-semibold">Quality Product</p>
          </div>
          <div className="w-[270px] py-4 flex flex-col items-center justify-center border border-2 border-[#195CA5] bg-white text-black service-card">
            <img
              src={softwareImg}
              alt="designImg"
              className="max-h-[95px] max-w-[95px]"
            />
            <p className="mt-4 text-[25px] font-semibold">
              Productivity Software{" "}
            </p>
          </div>
          <div className="w-[270px] py-4 flex flex-col items-center justify-center border border-2 border-[#195CA5] bg-white text-black service-card">
            <img
              src={onlineImg}
              alt="designImg"
              className="max-h-[95px] max-w-[95px]"
            />
            <p className="mt-4 text-[25px] font-semibold">
              24/7 Online Support
            </p>
          </div>
        </div>
      </div>
      {/** One Stop Solution */}
      <div className="mt-20 about-page-solution">
        <div className="container grid grid-cols-1 md:grid-cols-2 mx-auto items-center">
          <div className="py-12">
            <MiniReUseableTitle title="ONE-STOP SOLUTION" color="#195CA5" />
            <div className="mt-5 flex flex-row main-title">
              Data-Driven Digital Marketing
            </div>
            <div className="my-8 flex flex-row gap-4">
              <div className="mt-2 flex flex-col">
                <img src={informedImg} alt="missionImg" className="w-[60px]" />
              </div>
              <div className="flex flex-col">
                <h4 className="aboutOurCompanyCardTitle">Informed Outreach</h4>
                <p>
                  Harnessing data-driven insights, we tailor our digital
                  marketing strategies to resonate with the unique needs and
                  preferences of our audience.
                </p>
              </div>
            </div>
            <hr />
            <div className="my-8 flex flex-row gap-4">
              <div className="mt-2 flex flex-col">
                <img src={userImg} alt="vissionImg" className="w-[60px]" />
              </div>
              <div className="flex flex-col">
                <h4 className="aboutOurCompanyCardTitle">
                  User-Centric Innovation:
                </h4>
                <p>
                  Our commitment extends beyond software; data analytics guides
                  us in refining user experiences, ensuring our solutions align
                  precisely with user expectations.
                </p>
              </div>
            </div>
          </div>
          <div className="hidden md:block py-12">
            <img src={collegeImg} alt="collegeImg" className="max-h-[500px]" />
          </div>
        </div>
      </div>
      {/** About Page Footer */}
      <div className="container about-page-footer">
        <div className="flex flex-wrap gap-2 lg:gap-36 justify-center items-center h-full">
          <div className="flex flex-col">
            <div className="flex flex-row items-center">
              <p className="about-page-footer-para">10+</p>
              <p className="about-page-footer-text">YEARS OF EXPERIENCE</p>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-row items-center">
              <p className="about-page-footer-para">150+</p>
              <p className="about-page-footer-text">PROJECT COMPLETED</p>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-row items-center">
              <p className="about-page-footer-para">3600+</p>
              <p className="about-page-footer-text">
                Satisfied Clients on various Countries
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
