import React from "react";

const LevelingContent = ({ index, item, type }) => {
  return (
    <div className="flex gap-1">
      <div>
        {type === "ol" ? (
          <span className="black-point"> {`${index}. `}</span>
        ) : (
          <></>
        )}
        {type === "ul" ? (
          <span className="h-[26px] flex items-center pr-1">
            <span className="h-[4px] w-[4px] rounded-full bg-[#000000]"></span>
          </span>
        ) : (
          <></>
        )}
      </div>
      <div>
        <span className="black-point">{item.level}: </span>
        <span className="gray-point">{item.content}</span>
      </div>
    </div>
  );
};

export default LevelingContent;
