/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import internallink from "../assets/Components/Home/AboutOurCompany/internallink.png";
import techService from "../assets/Components/Home/AboutOurCompany/techService.png";
import HP from "../assets/Components/Home/ClientAndConcern/HP.png";
import Pub from "../assets/Components/Home/ClientAndConcern/Pub.png";
import como from "../assets/Components/Home/ClientAndConcern/como.png";
import dell from "../assets/Components/Home/ClientAndConcern/dell.png";
import honeywell from "../assets/Components/Home/ClientAndConcern/honeywell.png";
import hopeland from "../assets/Components/Home/ClientAndConcern/hopeland.png";
import omni from "../assets/Components/Home/ClientAndConcern/omni.png";
import sam from "../assets/Components/Home/ClientAndConcern/sam.png";
import sap from "../assets/Components/Home/ClientAndConcern/sap.png";
import smart from "../assets/Components/Home/ClientAndConcern/smart.png";
import tcb from "../assets/Components/Home/ClientAndConcern/tcb.png";
import zebra from "../assets/Components/Home/ClientAndConcern/zebra.png";
import Muehlbauer from "../assets/Components/Home/ClientAndConcern/Muehlbauer.png";
import eosdeploy from "../assets/Components/Home/DevopsSolution/eos-icons_deploy.png";
import icroundcode from "../assets/Components/Home/DevopsSolution/ic_round-code.png";
import laptop from "../assets/Components/Home/DevopsSolution/laptop.png";
import star from "../assets/Components/Home/DevopsSolution/star.png";
import enterpriseService from "../assets/Components/Home/Hero/enterpriseService.png";
import perfectBusinessSolution from "../assets/Components/Home/Hero/perfectBusinessSolution.png";
import softwareAsset from "../assets/Components/Home/Hero/softwareAsset.png";
import AssetVerificationSystem from "../assets/Components/Home/RecentlyCompletedWork/AssetVerificationSystem.webp";
import CareDrop from "../assets/Components/Home/RecentlyCompletedWork/CareDrop.webp";
import InventoryPOSSystem from "../assets/Components/Home/RecentlyCompletedWork/InventoryPOSSystem.webp";
import ScienceAugmentedReality from "../assets/Components/Home/RecentlyCompletedWork/ScienceAugmentedReality.webp";
import CareBox from "../assets/Components/Home/RecentlyCompletedWork/CareBox.webp";
import VlogFied from "../assets/Components/Home/RecentlyCompletedWork/VlogFied.webp";
import EDoctor from "../assets/Components/Home/RecentlyCompletedWork/EDoctor.webp";
import CommoditySmartLogo from "../assets/Components/Home/ServicesWeAreOffering/CommoditySmartLogo.png";
import EducationSolutionLogo from "../assets/Components/Home/ServicesWeAreOffering/EducationSolutionLogo.png";
import HealthcareSolutionLogo from "../assets/Components/Home/ServicesWeAreOffering/HealthcareSolutionLogo.png";
import HomeIndustryLogo from "../assets/Components/Home/ServicesWeAreOffering/HomeIndustryLogo.png";
import IOSAndroidAppLogo from "../assets/Components/Home/ServicesWeAreOffering/IOSAndroidAppLogo.png";
import RFIDBarcodeLogo from "../assets/Components/Home/ServicesWeAreOffering/RFIDBarcodeLogo.png";
import smartCardSolutionLogo from "../assets/Components/Home/ServicesWeAreOffering/smartCardSolutionLogo.png";
import webCloudServiceLogo from "../assets/Components/Home/ServicesWeAreOffering/webCloudServiceLogo.png";
import Flutter from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/Android/Flutter.png";
import Express from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/Backend/Express.png";
import Java from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/Backend/Java.png";
import Node from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/Backend/Node.png";
import Python from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/Backend/Python.png";
import Shopify from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/CMSWEB/Shopify.png";
import WordPress from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/CMSWEB/WordPress.png";
import AWS from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/Cloud/AWS.png";
import Ansible from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/Cloud/Ansible.png";
import ApacheAirflow from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/Cloud/ApacheAirflow.png";
import CloudFormation from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/Cloud/CloudFormation.png";
import Docker from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/Cloud/Docker.png";
import Grafana from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/Cloud/Grafana.png";
import Kubernetes from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/Cloud/Kubernetes.png";
import Prometheus from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/Cloud/Prometheus.png";
import Terraform from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/Cloud/Terraform.png";
import MongoDB from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/Database/MongoDB.png";
import MySQL from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/Database/MySQL.png";
import PostgreSQL from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/Database/PostgreSQL.png";
import BootstrapLogo from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/FrontEnd/BootstrapLogo.png";
import HTMLCSS3Logo from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/FrontEnd/HTMLCSS3Logo.png";
import JavaScriptLogo from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/FrontEnd/JavaScriptLogo.png";
import NextJSLogo from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/FrontEnd/NextJSLogo.png";
import TailwindCSSLogo from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/FrontEnd/TailwindCSSLogo.png";
import TypeScriptLogo from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/FrontEnd/TypeScriptLogo.png";
import VueJSLogo from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/FrontEnd/VueJSLogo.png";
import reactLogo from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/FrontEnd/reactLogo.png";
import Swift from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/IOS/Swift.png";
import ABTesting from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/Testing/ABTesting.png";
import ManualTesting from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/Testing/ManualTesting.png";
import PerformanceTesting from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/Testing/PerformanceTesting.png";
import Selenium from "../assets/Components/Home/TechnologyThornWeUse/TechnologyThornWeUseDetails/Testing/Selenium.png";
import ambu from "../assets/Components/Home/ClientAndConcern/ambu.png";
import selp from "../assets/Components/Home/ClientAndConcern/selp.png";
import demcon from "../assets/Components/Home/ClientAndConcern/demcon.png";
import devopsLogo from "../assets/Components/Home/ClientAndConcern/devopsglogo.png";

export const heroFooterSectionCardData = [
  {
    id: 1,
    title: "Software Asset",
    content:
      "All aspects of your software assets including purchasing, deployment & maintenance. ",
    imgae: softwareAsset,
  },

  {
    id: 2,
    title: "Enterprise Service",
    content:
      "Extend proven Tech best practices to HR, finance, marketing and other service delivery areas.",
    imgae: enterpriseService,
  },

  {
    id: 3,
    title: "Perfect Business Solution",
    content:
      "We help businesses like yours earn more customers, stand out from competitors, and make more money.",
    imgae: perfectBusinessSolution,
  },
];

export const AboutOurCompanyCardData = [
  {
    id: 1,
    title: "Custom Software Development",
    content:
      "Bespoke, scalable solutions to enhance productivity and drive growth.",
    imgae: techService,
  },

  {
    id: 2,
    title: "Digital Transformation Consulting",
    content: "Expert guidance to modernize operations and embrace innovation.",
    imgae: internallink,
  },
];

export const servicesWeAreOfferingCardData = [
  {
    id: 1,
    title: "SmartCard Solution",
    content:
      "Secure, seamless, versatile solution for modern access and transactions.",
    imgae: smartCardSolutionLogo,
  },

  {
    id: 2,
    title: "IOS/Android App",
    content:
      "Craft efficient apps for optimal user experiences and digital excellence.",
    imgae: IOSAndroidAppLogo,
  },

  {
    id: 3,
    title: "Web & Cloud Service",
    content:
      "Integration, scalable solutions for advanced, efficient online services.",
    imgae: webCloudServiceLogo,
  },

  {
    id: 4,
    title: "RFID/Barcode",
    content: "Streamlined tracking for precise, error-free data management.",
    imgae: RFIDBarcodeLogo,
  },

  {
    id: 5,
    title: "Education Solution",
    content: "Innovate learning with technology, empower future.",
    imgae: EducationSolutionLogo,
  },

  {
    id: 6,
    title: "Healthcare Solution",
    content: "Innovative, precise solutions for optimal healthcare outcomes.",
    imgae: HealthcareSolutionLogo,
  },

  {
    id: 7,
    title: "Home & Industry",
    content:
      "Smart solutions for seamless living and efficient industrial operations.",
    imgae: RFIDBarcodeLogo,
  },

  {
    id: 8,
    title: "Commodity Smart",
    content:
      "Elevate commodity management with efficient, tech-driven solutions.",
    imgae: CommoditySmartLogo,
  },
];
export const servicesWeAreOfferingCardDataColumn1 = [
  {
    id: 1,
    title: "SmartCard Solution",
    content:
      "Secure, seamless, versatile solution for modern access and transactions.",
    imgae: smartCardSolutionLogo,
    serviceId: 9,
  },

  {
    id: 2,
    title: "IOS/Android App",
    content:
      "Custom iOS/Android Apps Designed to Optimize Your Business Operations.",
    imgae: IOSAndroidAppLogo,
    serviceId: 2,
  },

  {
    id: 3,
    title: "Web & Cloud Service",
    content:
      "Optimized Web & Cloud Solutions for Enhanced Performance and Security.",
    imgae: webCloudServiceLogo,
    serviceId: 1,
  },

  {
    id: 4,
    title: "RFID/Barcode",
    content: "Efficient solutions to optimize asset and inventory tracking",
    imgae: RFIDBarcodeLogo,
    serviceId: 12,
  },
];

export const servicesWeAreOfferingCardDataColumn2 = [
  {
    id: 5,
    title: "Education Solution",
    content:
      "Simplifying education administration and learning with advanced solutions.",
    imgae: EducationSolutionLogo,
    serviceId: 14,
  },

  {
    id: 6,
    title: "Healthcare Solution",
    content:
      "Reliable healthcare solutions to improve patient outcomes and care.",
    imgae: HealthcareSolutionLogo,
    serviceId: 15,
  },

  {
    id: 7,
    title: "Home & Industry",
    content:
      "Smart solutions designed for residential and industrial applications.",
    imgae: HomeIndustryLogo,
    serviceId: 13,
  },

  {
    id: 8,
    title: "Commodity Smart",
    content:
      "Revolutionizing commodity processes with advanced technology solutions.",
    imgae: CommoditySmartLogo,
    serviceId: 16,
  },
];

export const technologyThornWeUseData = [
  {
    id: 1,
    title: "FRONTEND",
    details: [
      {
        id: 1,
        title: "React JS",
        image: reactLogo,
      },
      {
        id: 2,
        title: "Next JS",
        image: NextJSLogo,
      },
      {
        id: 3,
        title: "Vue JS",
        image: VueJSLogo,
      },
      {
        id: 4,
        title: "JavaScript",
        image: JavaScriptLogo,
      },

      {
        id: 5,
        title: "TypeScript",
        image: TypeScriptLogo,
      },
      {
        id: 6,
        title: "HTML/CSS3",
        image: HTMLCSS3Logo,
      },
      {
        id: 7,
        title: "Bootstrap",
        image: BootstrapLogo,
      },
      {
        id: 8,
        title: "Tailwind CSS",
        image: TailwindCSSLogo,
      },
    ],
  },

  {
    id: 2,
    title: "BACKEND",
    details: [
      {
        id: 1,
        title: "Python",
        image: Python,
      },
      {
        id: 2,
        title: "Java",
        image: Java,
      },
      {
        id: 3,
        title: "Node JS",
        image: Node,
      },
      {
        id: 4,
        title: "Express JS",
        image: Express,
      },
      {
        id: 5,
        title: "MySQL",
        image: MySQL,
      },
      {
        id: 6,
        title: "PostgreSQL",
        image: PostgreSQL,
      },
      {
        id: 7,
        title: "MongoDB",
        image: MongoDB,
      },
    ],
  },

  {
    id: 3,
    title: "ANDROID / IOS",
    details: [
      {
        id: 1,
        title: "Flutter",
        image: Flutter,
      },
      {
        id: 2,
        title: "React Native",
        image: reactLogo,
      },
      {
        id: 3,
        title: "Swift",
        image: Swift,
      },
    ],
  },

  {
    id: 4,
    title: "CMS/WEB",
    details: [
      {
        id: 1,
        title: "WordPress",
        image: WordPress,
      },
      {
        id: 2,
        title: "Shopify",
        image: Shopify,
      },
    ],
  },

  {
    id: 5,
    title: "TESTING",
    details: [
      {
        id: 1,
        title: "Selenium",
        image: Selenium,
      },
      {
        id: 2,
        title: "A/B Testing",
        image: ABTesting,
      },
      {
        id: 3,
        title: "Performance Testing",
        image: PerformanceTesting,
      },
      {
        id: 4,
        title: "Manual Testing",
        image: ManualTesting,
      },
    ],
  },
  {
    id: 6,
    title: "DevOps",
    details: [
      {
        id: 1,
        title: "AWS",
        image: AWS,
      },
      {
        id: 2,
        title: "Kubernetes",
        image: Kubernetes,
      },
      {
        id: 3,
        title: "Docker",
        image: Docker,
      },
      {
        id: 4,
        title: "Apache Airflow",
        image: ApacheAirflow,
      },
      {
        id: 5,
        title: "Prometheus",
        image: Prometheus,
      },
      {
        id: 6,
        title: "Grafana",
        image: Grafana,
      },
      {
        id: 7,
        title: "Cloud Formation",
        image: CloudFormation,
      },
      {
        id: 8,
        title: "Terraform",
        image: Terraform,
      },
      {
        id: 9,
        title: "Ansible",
        image: Ansible,
      },
    ],
  },
];

export const whyChooseSmartmatrixList = [
  {
    id: 1,
    title: "Latest Technologies",
    content:
      "SmartMatrix embraces the latest technologies in software development, leveraging cutting-edge tools like AI, blockchain, cloud",
  },

  {
    id: 2,
    title: "Unique Solutions",
    content:
      "SmartMatrix delivers a distinctive software development solution by blending...Read More",
  },

  {
    id: 3,
    title: "Powerful Strategies",
    content:
      "Adhering to rigorous quality management procedures and meeting global security",
  },
];

export const clientAndConcernData = [
  {
    id: 1,
    image: zebra,
  },
  {
    id: 2,
    image: Pub,
  },
  {
    id: 3,
    image: dell,
  },

  {
    id: 4,
    image: honeywell,
  },
  {
    id: 5,
    image: hopeland,
  },
  {
    id: 6,
    image: omni,
  },

  {
    id: 7,
    image: HP,
  },
  {
    id: 8,
    image: como,
  },
  {
    id: 9,
    image: smart,
  },
  {
    id: 10,
    image: tcb,
  },
  // {
  //   id: 11,
  //   image: sap
  // },
  {
    id: 12,
    image: sam,
  },
  {
    id: 13,
    image: Muehlbauer,
  },
  {
    id: 14,
    image: ambu,
  },
  {
    id: 15,
    image: selp,
  },
  {
    id: 16,
    image: demcon,
  },
  {
    id: 17,
    image: devopsLogo,
  },
];

export const clientAndConcernDataColumn1 = [
  {
    id: 1,
    image: zebra,
  },
  {
    id: 2,
    image: Pub,
  },
  {
    id: 3,
    image: dell,
  },

  {
    id: 4,
    image: honeywell,
  },
];

export const clientAndConcernDataColumn2 = [
  {
    id: 5,
    image: hopeland,
  },
  {
    id: 6,
    image: omni,
  },

  {
    id: 7,
    image: HP,
  },
  {
    id: 8,
    image: como,
  },
];

export const clientAndConcernDataColumn3 = [
  {
    id: 9,
    image: smart,
  },
  {
    id: 10,
    image: tcb,
  },
  {
    id: 11,
    image: sap,
  },
  {
    id: 12,
    image: sam,
  },
];

export const devopsSolutionData = [
  {
    id: 1,
    title: "Infrastructure Automation",
    list: [
      "a) Automated Provisioning",
      "b) Scaling Server in very less time",
      "c) Eliminating mismatched server state",
      "d) To bring servers at deploy ready state",
    ],
    image: laptop,
  },
  {
    id: 2,
    title: "Configuration Management",
    list: [
      "a) Eliminating mismatch of configuration",
      "b) Error-free and faster configuration deployment",
      "c) All tools managed by a single tool",
      "d) Configuration activity reports",
    ],
    image: star,
  },
  {
    id: 3,
    title: "Code Inspection & Integration",
    list: [
      "a) A well tested code",
      "b) Improved code quality",
      "c) Verified codes for development",
      "d) Build and code quality reports",
    ],
    image: icroundcode,
  },
  {
    id: 4,
    title: "Deployment Automation",
    list: [
      "a) Error free and faster deployment",
      "b) Continuous and uninterrupted deployment",
      "c) Deployment by single tool in all environments",
      "d) deployment metrics",
    ],
    image: eosdeploy,
  },
];

export const recentlyCompletedWorkCard = [
  {
    id: 1,
    title: "Asset Verification System",
    cetagories: "App & Web / Design / Implementation / Maintenance",
    list: [
      "App & Web Portal",
      "RFID & Barcode Scan",
      "Theft Prevention & Recovery",
      "Compliance & Reporting",
      "Financial Reporting",
      "Audit Trail",
      "Inventory Reconciliation",
      "Asset Location Tracking",
      "Curated Notification System",
    ],
    image: AssetVerificationSystem,
  },
  {
    id: 2,
    title: "Care - Box Ecommerce",
    cetagories: "App & Web / Design / Ideas",
    list: [
      "App & Web Portal",
      "Native App for Android & IOS",
      "Search Engine, Categories",
      "Live Tracking System",
      "Online Payment System",
      "High-End Security for Users",
    ],
    image: CareBox,
  },
  {
    id: 3,
    title: "Inventory & POS System",
    cetagories: "Web / Design / Ideas / Implementation / Maintenance",
    list: [
      "Web Portal",
      "Inventory Management",
      "POS System",
      "Sales Management",
      "Product Management",
      "Product Tracking",
    ],
    image: InventoryPOSSystem,
  },
  {
    id: 4,
    title: "E-Doctor Consultation",
    cetagories: "App / Web / Design / Ideas / Implementation / Maintenance",
    list: [
      "App & Website",
      "Instant Doctor Access",
      "Video Call",
      "E-Prescriptions",
      "Medical History Management",
      "Symptom Checker",
      "Appointment Scheduling",
      "24/7 Availability",
    ],
    image: EDoctor,
  },
  {
    id: 5,
    title: "VlogFied",
    cetagories: "App / Web / Design / Ideas / Maintenance",
    list: [
      "App & Website",
      "Advanced Search & Filtering",
      "Real Time Alert System",
      "Map Wise Search",
      "User-Friendly Design",
      "Categories/Tags",
      "User-friendly Vlog Details",
      "User Feedback Section",
    ],
    image: VlogFied,
  },
  {
    id: 4,
    title: "CareDrop",
    cetagories: "App / Web / Design / Ideas / Implementation / Maintenance",
    list: [
      "App & Website",
      "Blog Post",
      "User-Friendly Design",
      "Search Functionality",
      "Author Profiles",
      "Categories/Tags",
      "Security Measures",
      "Loading Speed Optimization",
    ],
    image: CareDrop,
  },
  {
    id: 2,
    title: "Science Augmented Reality",
    cetagories: "App & Web / Design / Ideas",
    list: [
      "App & Web Portal",
      "Scan Images",
      "3D Model Appears",
      "Character Description",
      "User Management",
      "Content Management",
    ],
    image: ScienceAugmentedReality,
  },
];
