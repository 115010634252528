/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";

function PowerfulStrategiesItem() {
  const [readMoreStatus, setReadMoreStatus] = useState(true);

  let content;

  if (readMoreStatus) {
    content =
      "With powerful strategies and innovative solutions, SmartMatrix ensures your business....";
  } else {
    content =
      "With powerful strategies and innovative solutions, SmartMatrix ensures your business stays ahead. We deliver impactful results that enhance performance and foster long-term success.";
  }

  return (
    <div className="flex gap-3">
      <div className="whyChooseSmartmatrixListItemNumber">03.</div>
      <div>
        <div className="sectionListTitle">Powerful Strategies</div>
        <div className="sectionListListContent">
          {content}
          {readMoreStatus && (
            <span
              className="text-[#195CA5] font-semibold cursor-pointer"
              onClick={() => setReadMoreStatus(false)}
            >
              Read More
            </span>
          )}
          {!readMoreStatus && (
            <span
              className="text-[#195CA5] font-semibold cursor-pointer"
              onClick={() => setReadMoreStatus(true)}
            >
              Read Less
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default PowerfulStrategiesItem;
