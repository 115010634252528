/* eslint-disable import/prefer-default-export */
import Drones from "../assets/Components/ECityDetails/Drones/Drones.webp";
import EParking from "../assets/Components/ECityDetails/E-Parking/E-Parking.webp";
import ehealth from "../assets/Components/ECityDetails/E-health/ehealth.webp";
import EEnvironment from "../assets/Components/ECityDetails/EEnvironment/EEnvironment.webp";
import EServicesPlatform from "../assets/Components/ECityDetails/EServicesPlatform/EServicesPlatform.webp";
import EID from "../assets/Components/ECityDetails/eid/EID.png";
import SmartGrid from "../assets/Components/ECityDetails/smart-grid/SmartGrid.webp";
import Vision from "../assets/Components/Ecity/ECitySolution/Vision.png";
import mission from "../assets/Components/Ecity/ECitySolution/mission.png";
import INPLOIntermodal from "../assets/Components/Ecity/ServicesForEcity/INPLOIntermodal.png";
import eid from "../assets/Components/Ecity/ServicesForEcity/arcticons_kopieid.png";
import drone from "../assets/Components/Ecity/ServicesForEcity/carbon_drone.png";
import windpower from "../assets/Components/Ecity/ServicesForEcity/carbon_wind-power.png";
import eEnvironment from "../assets/Components/Ecity/ServicesForEcity/eEnvironment.png";
import eParking from "../assets/Components/Ecity/ServicesForEcity/eParking.png";
import eServicesPlatform from "../assets/Components/Ecity/ServicesForEcity/eServicesPlatform.png";
import telehealth from "../assets/Components/Ecity/ServicesForEcity/telehealth.png";
import eHealthWork1 from "../assets/Components/ECityDetails/E-health/eHealthWork1.png";
import eHealthWork2 from "../assets/Components/ECityDetails/E-health/eHealthWork2.png";
import eHealthWork3 from "../assets/Components/ECityDetails/E-health/eHealthWork3.png";
import eHealthBottomImage from "../assets/Components/ECityDetails/E-health/eHealthBottomImage.png";
import droneswork1 from "../assets/Components/ECityDetails/Drones/droneswork1.png";
import droneswork2 from "../assets/Components/ECityDetails/Drones/droneswork2.png";
import droneswork3 from "../assets/Components/ECityDetails/Drones/droneswork3.png";
import dronesBottomImage from "../assets/Components/ECityDetails/Drones/dronesBottomImage.png";
import smartGirdWork1 from "../assets/Components/ECityDetails/smart-grid/smartGirdWork1.png";
import smartGirdWork2 from "../assets/Components/ECityDetails/smart-grid/smartGirdWork2.png";
import smartGirdWork3 from "../assets/Components/ECityDetails/smart-grid/smartGirdWork3.png";
import smartGirdBottomImage from "../assets/Components/ECityDetails/smart-grid/smartGirdBottomImage.png";
import eidwork1 from "../assets/Components/ECityDetails/eid/eidwork1.png";
import eidwork2 from "../assets/Components/ECityDetails/eid/eidwork2.png";
import eidBottomImage from "../assets/Components/ECityDetails/eid/eidBottomImage.webp";
import eParkingBottomImage from "../assets/Components/ECityDetails/E-Parking/eParkingBottomImage.png";
import eEnvironmentBottomImage from "../assets/Components/ECityDetails/EEnvironment/eEnvironmentBottomImage.png";
import eServicesBottomImage from "../assets/Components/ECityDetails/EServicesPlatform/eServicesBottomImage.png";

import INPLOIntermodalwork1 from "../assets/Components/ECityDetails/INPLOIntermodal/INPLOIntermodalwork1.png";
import INPLOIntermodalwork2 from "../assets/Components/ECityDetails/INPLOIntermodal/INPLOIntermodalwork2.png";
import INPLOIntermodalwork3 from "../assets/Components/ECityDetails/INPLOIntermodal/INPLOIntermodalwork3.png";
import INPLOIntermodalDetailsCover from "../assets/Components/ECityDetails/INPLOIntermodal/INPLO-Intermodal.webp";

export const eCitySolutionCardData = [
  {
    id: 1,
    title: "Smart Infrastructure",
    image: mission,
    contentList: [
      "E-City solutions integrate advanced technologies for efficient city management.",
      "Smart grids, intelligent traffic systems, and connected infrastructure enhance urban living.",
    ],
  },
  {
    id: 2,
    title: "Digital Governance",
    contentList: [
      "Implementing E-City solutions transforms governance through digital platforms.",
      "Online services, data analytics, and citizen engagement tools optimize civic administration.",
    ],
    image: Vision,
  },
];

export const servicesForEcityCardDataColumn1 = [
  {
    id: 1,
    title: "E-Health",
    content:
      "E-Health for citizen well-being, modern healthcare for happiness.",
    imgae: telehealth,
  },

  {
    id: 2,
    title: "Drones",
    content:
      'Drones soar, enhancing smart cities with efficient services from above."',
    imgae: drone,
  },

  {
    id: 3,
    title: "Smart Grid",
    content:
      "Smart Grid cities, optimizing energy use for sustainability and resilience.",
    imgae: windpower,
  },

  {
    id: 4,
    title: "E-ID",
    content:
      "Efficient, Innovative, and Digital solutions for urban prosperity.",
    imgae: eid,
  },
];

export const servicesForEcityCardDataColumn2 = [
  {
    id: 5,
    title: "E-Parking",
    content:
      "Digital solutions for efficient and convenient parking management.",
    imgae: eParking,
  },

  {
    id: 6,
    title: "E-Environment",
    content:
      "Tech-driven solutions fostering sustainability and eco-friendly living.",
    imgae: eEnvironment,
  },

  {
    id: 7,
    title: "E-Services Platform",
    content:
      "Streamlined digital hub for seamless, citizen-centric urban solutions.",
    imgae: eServicesPlatform,
  },

  {
    id: 8,
    title: "INPLO Intermodal",
    content: "Seamless urban logistics for a smarter, connected cityscape.",
    imgae: INPLOIntermodal,
  },
];

export const eCityDetailsData = [
  {
    id: 1,
    title: "E-Health",
    image: ehealth,
    alt: "ehealth",
    headerTitle: "Elevate City Health with Innovation.",
    headerDescription:
      "<p>SmartMatrix is presently engaged in diverse E-City solutions, aligning seamlessly with our strategy for a strong international presence. With a commitment to innovation, we shape urban environments, optimizing resources and creating intelligent solutions that redefine the way cities function and thrive.</p><br/><p>City dwellers' satisfaction, well-being, and prosperity hinge on vital services. Swift access to healthcare is pivotal for citizen contentment. In today's era, convenient access often aligns with mobile or electronic platforms. Hence, smart cities are progressively integrating e-health services to cater to the evolving needs of their residents.</p>",
    cardGallery: [
      {
        id: 1,
        title: "HEALTH INFORMATION EXCHANGE INFRASTRUCTURE",
        image: eHealthWork1,
      },
      {
        id: 2,
        title: "MEDICAL REGISTERS PLATFORM",
        image: eHealthWork2,
      },
      {
        id: 3,
        title: "DOMAIN SYSTEMS IN HEALTHCARE",
        image: eHealthWork3,
      },
    ],
    bottomImage: eHealthBottomImage,
    metaTitle:
      "Elevate City Health with Innovation | SmartMatrix E-City Solutions",
    metaDescription:
      "SmartMatrix enhances urban living with innovative e-health solutions, ensuring swift healthcare access and smarter city services for a thriving community.",
  },
  {
    id: 2,
    title: "Drones",
    image: Drones,
    alt: "Drones",
    headerTitle: "Modern Air Transport",
    headerDescription:
      "<p>AirVein marks a pioneering endeavor globally as the initial project of its kind. It represents a fully self-sufficient transportation system employing cargo drones. This system is specifically designed for the U-Space, referring to urban airspace. It encompasses sophisticated control software, a fleet of durable and secure unmanned aerial vehicles, ground infrastructure, and dependable redundant communication.</p>",
    cardGallery: [
      {
        id: 1,
        title: "TRANSPORT",
        image: droneswork1,
      },
      {
        id: 2,
        title: "INSPECTIONS",
        image: droneswork2,
      },
      {
        id: 3,
        title: "MONITORING",
        image: droneswork3,
      },
    ],
    bottomImage: dronesBottomImage,
    metaTitle:
      "AirVein | Revolutionizing Urban Air Transport with Cargo Drones",
    metaDescription:
      "AirVein pioneers self-sufficient cargo drone transport in urban airspace, ensuring secure, efficient logistics with advanced control and communication systems.",
  },
  {
    id: 3,
    title: "Smart Grid",
    image: SmartGrid,
    alt: "SmartGrid",
    headerTitle: "Smart Grid – intelligent solutions in power engineering",
    headerDescription:
      "<p>Presently, the progress of the energy sector relies on holistic approaches and investments in Smart Grid technology. Through collaboration with our partners, we assist clients in addressing the complexities of constructing cutting-edge, intelligent energy systems. Smart Grid facilitates the control of electricity production, storage, and distribution, encompassing components and sub-assemblies of the power grid.</p>",
    cardGallery: [
      {
        id: 1,
        title: "Smart Metering",
        image: smartGirdWork1,
      },
      {
        id: 2,
        title: "SCADA & MDM",
        image: smartGirdWork2,
      },
      {
        id: 3,
        title: "Billing",
        image: smartGirdWork3,
      },
    ],
    bottomImage: smartGirdBottomImage,
    metaTitle:
      "Smart Grid | Intelligent Energy Solutions for a Sustainable Future",
    metaDescription:
      "Smart Grid technology optimizes energy production, storage, and distribution, enabling advanced metering, SCADA, and efficient billing for a smarter power grid.",
  },
  {
    id: 4,
    title: "E-ID",
    image: EID,
    alt: "EID",
    headerTitle: "E-ID Solution",
    headerDescription:
      "<p>eID facilitates the remote finalization of contracts or submission of applications, possessing legal validity equivalent to an electronic signature. Establishing an account or signing a contract no longer necessitates the client or applicant's physical presence at a service point for identity verification or document signing. Frequently, digital service processes face interruptions for identity confirmation, and certain attributes like address or age hold significance, especially for media providers. Swift confirmation of e-identity paves the way for innovative service companies to explore new business avenues within the e-business ecosystem.</p>",
    cardGallery: [
      {
        id: 1,
        title: "TRUSTED PROFILE",
        image: eidwork1,
      },
      {
        id: 2,
        title: "MYID",
        image: eidwork2,
      },
    ],
    bottomImage: eidBottomImage,
    metaTitle:
      "E-ID Solution | Secure & Legally Valid Digital Identity Verification",
    metaDescription:
      "E-ID enables remote contract signing and identity verification, ensuring secure, legally valid transactions for seamless digital services and e-business growth.",
  },
  {
    id: 5,
    title: "E-Parking",
    image: EParking,
    alt: "E-Parking",
    headerTitle: "E-Parking Solution",
    headerDescription:
      "<p>e-Parking integrates an electronic map gathering data on parking space availability within its operational area. It serves as an information system for drivers, offering a map displaying vacant parking spaces in real-time through sensors or cameras. Utilizing a mobile app, drivers can efficiently plan routes, and if necessary, make parking payments. The dynamic parking information not only saves users time and fuel by eliminating the need to search for parking but also contributes to environmental conservation by reducing carbon dioxide emissions. Additionally, the system enhances urban transportation by optimizing parking space usage and decreasing the overall number of cars.</p>",
    bottomImage: eParkingBottomImage,
    metaTitle:
      "E-Parking Solution | Smart Parking for Efficient Urban Mobility",
    metaDescription:
      "E-Parking provides real-time parking availability, route planning, and mobile payments, reducing congestion, saving fuel, and lowering CO₂ emissions.",
  },
  {
    id: 6,
    title: "E-Environment",
    image: EEnvironment,
    alt: "EEnvironment",
    headerTitle: "Environmental Monitoring System",
    headerDescription:
      "<p>Our water quality management solution facilitates the tracking of sampling processes, generates region-specific monitoring reports, and ensures continuous compliance with regulations. It enables the planning of sampling activities and establishes a chain of custody. Enhancing visibility, it identifies areas for improvement, tracks changes over time, and offers comprehensive dashboards for a holistic view of the organization's water regulation performance. The data can be organized based on source location, parameters, or region. The system maintains an accurate and well-organized electronic record of reports, designed to be audit-friendly. It simplifies the process of achieving and demonstrating compliance with water regulations pertinent to your organization, irrespective of industry or location.</p>",
    bottomImage: eEnvironmentBottomImage,
    metaTitle:
      "Environmental Monitoring System | Smart Water Quality Management",
    metaDescription:
      "Track water quality, ensure compliance, and generate monitoring reports with our smart environmental system for accurate, audit-friendly regulation management.",
  },
  {
    id: 7,
    title: "E-Services Platform",
    image: EServicesPlatform,
    alt: "EServicesPlatform",
    headerTitle: "E-Services Platform Solution",
    headerDescription:
      "<p>The e-Services Platform in a E-City acts as a digital nexus, seamlessly connecting residents with a myriad of essential services. From streamlined administrative processes to citizen-centric solutions, it forms the backbone of a modern urban ecosystem, fostering efficiency, accessibility, and overall well-being.</p>",
    bottomImage: eServicesBottomImage,
    metaTitle: "E-Services Platform | Smart Digital Solutions for Urban Living",
    metaDescription:
      "The E-Services Platform connects residents to essential digital services, enhancing efficiency, accessibility, and well-being in modern urban ecosystems.",
  },
  {
    id: 8,
    title: "INPLO Intermodal",
    image: INPLOIntermodalDetailsCover,
    alt: "INPLOIntermodal",
    headerTitle: "INPLO Intermodal Logistics Platform",
    headerDescription:
      "<p>The INPLO Intermodal Logistics Platform aids in the coordination, supervision, invoicing, and administration of transportation. It caters to businesses engaged in transport management, provision of transport or forwarding services, as well as trading entities and distributors.</p>",
    cardGallery: [
      {
        id: 1,
        title: "Module smartAWI",
        image: INPLOIntermodalwork1,
      },
      {
        id: 2,
        title: "Module smartTMS",
        image: INPLOIntermodalwork2,
      },
      {
        id: 3,
        title: "TMS consulting",
        image: INPLOIntermodalwork3,
      },
    ],
    metaTitle: "INPLO | Smart Intermodal Logistics & Transport Management",
    metaDescription:
      "INPLO streamlines transport coordination, invoicing, and administration for logistics providers, traders, and distributors with smartAWI and smartTMS modules.",
  },
];
