import React from "react";
import Footer from "../../Components/Footer/Footer";
import AboutOurCompany from "../../Components/Home/AboutOurCompany/AboutOurCompany";
import ClientAndConcern from "../../Components/Home/ClientAndConcern/ClientAndConcern";
import DevopsSolution from "../../Components/Home/DevopsSolution/DevopsSolution ";
import Hero from "../../Components/Home/Hero/Hero";
import MapSection from "../../Components/Home/MapSection/MapSection";
import RecentlyCompletedWork from "../../Components/Home/RecentlyCompletedWork/RecentlyCompletedWork";
import ServicesWeAreOffering from "../../Components/Home/ServicesWeAreOffering/ServicesWeAreOffering";
import TechManagement from "../../Components/Home/TechManagement/TechManagement";
import TechnologyThornWeUse from "../../Components/Home/TechnologyThornWeUse/TechnologyThornWeUse";
import WhyChooseSmartmatrix from "../../Components/Home/WhyChooseSmartmatrix/WhyChooseSmartmatrix";
import Workflow from "../../Components/Home/Workflow/Workflow";
import Navbar from "../../Components/Navbar/Navbar";
import "./Home.css";

import { Helmet } from "react-helmet-async";

function Home() {
  return (
    <div>
      <Helmet>
        <title>SmartMatrix - Providing The Best Services & IT Solutions</title>
        <meta
          name="description"
          content="SmartMatrix PTE Ltd., based in Singapore, offers innovative, tech-driven solutions for seamless living and efficient industrial operations. As the sole agent, consultant, and distributor for leading global brands in the industrial and IT sectors, we serve the Asian and European markets, providing critical after-sales services. Contact us at info@smartmatrix.com.sg"
        />
        <meta
          name="keywords"
          content="SmartMatrix, SmartMatrix PTE Ltd., Singapore, innovative solutions, tech-driven solutions, industrial operations, IT sector, global brands, Asian market, European market, after-sales services, commodity management, technology consultancy, industrial solutions, efficient operations, contact information"
        />
      </Helmet>

      <Navbar />
      <Hero />
      <AboutOurCompany />
      <ServicesWeAreOffering />
      <TechnologyThornWeUse />
      <Workflow />
      <WhyChooseSmartmatrix />
      <ClientAndConcern />
      <TechManagement />
      <DevopsSolution />
      <RecentlyCompletedWork />
      <MapSection />
      <Footer />
    </div>
  );
}

export default Home;
