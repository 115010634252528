import React from "react";
import ECitySolution from "../../Components/Ecity/ECitySolution/ECitySolution";
import Hero from "../../Components/Ecity/Hero/Hero";
import ServicesForEcity from "../../Components/Ecity/ServicesForEcity/ServicesForEcity";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import ReuseableFooterBanner from "../../Components/reuseableComponents/reuseableFooterBanner/ReuseableFooterBanner";
import bannerImage from "../../assets/Components/Ecity/FooterBanner/leftSideBannerImage.png";
import { Helmet } from "react-helmet-async";

function Ecity() {
  return (
    <div>
      <Navbar />
      <Hero />
      <ECitySolution />
      <ServicesForEcity />

      <Helmet>
        <title>E-City Solutions | Shaping Smart Urban Futures</title>
        <meta
          name="description"
          content="Explore E-City solutions by SmartMatrix, integrating smart infrastructure, digital governance, and innovation to optimize urban living and enhance city management."
        />
      </Helmet>

      <div className="mt-[45px] lg:mt-[90px]">
        <ReuseableFooterBanner
          bannerImage={bannerImage}
          title="Starting with SmartMatrix is easy, fast and free"
          buttonName="GET STARTED"
          url="/contact"
        />
      </div>
      <Footer />
    </div>
  );
}

export default Ecity;
