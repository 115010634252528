import React from "react";

const ServicesOLUL = ({ index, item }) => {
  return (
    <div className="black-point pb-2">
      {`${index}. ${item.title}`}
      <div className="gray-point flex flex-col gap-[3px] pl-5">
        {item.points.map((point, i) => (
          <div className="h-full flex items-start gap-1" key={i}>
            <div className="h-[22px] flex items-center">
              <div className="h-[4px] w-[4px] rounded-full bg-[#858E96]"></div>
            </div>
            <div>{point}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesOLUL;
