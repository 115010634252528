/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { faChevronRight, faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import leftSideBannerImage from "../../assets/Components/Home/MapSection/leftSideBannerImage.png";
import bannerImage from "../../assets/Components/Services/Rectangle 26.png";
import facebookImg from "../../assets/Components/Services/fe_facebook.png";
import linkdinImg from "../../assets/Components/Services/uil_linkedin.png";
import { serviceData } from "../../store/serviceStore";
import "../Career/Career.css";
import "./Services.css";
import { Helmet } from "react-helmet-async";
import ServicesOLUL from "./ServicesOLUL/ServicesOLUL";
import LevelingContent from "./LevelingContent/LevelingContent";
import ReuseableFooterBanner from "../../Components/reuseableComponents/reuseableFooterBanner/ReuseableFooterBanner";

function Services() {
  const navigate = useNavigate();
  const [selectedService, setSelectedService] = useState(1);

  // console.log("selectedService = ", selectedService);

  const { id: paramId } = useParams();
  // console.log('paramId = ', paramId);

  useEffect(() => {
    if (paramId) {
      setSelectedService(parseInt(paramId));
    }
  }, [paramId]);

  useEffect(() => {
    window.scrollTo({
      top: 400,
      behavior: "smooth",
    });
    // if (localStorage.getItem("service-id")) {
    //   setSelectedService(parseInt(localStorage.getItem("service-id")));
    // }
  }, [paramId]);
  // console.log(localStorage.getItem("service-id"));
  return (
    <>
      <Navbar />
      <div className="relative">
        <div className="career-hero">
          <div className="container flex flex-col justify-center h-full">
            <div className="flex flex-row heroTitle">Solution & Services</div>
            <div className="mt-6 flex flex-row items-center">
              <FontAwesomeIcon icon={faHouse} color="white" />
              <FontAwesomeIcon
                icon={faChevronRight}
                color="white"
                style={{ paddingLeft: "1rem" }}
              />
              <p
                className="text-white"
                style={{
                  paddingLeft: "1rem",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                Services
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="my-20 container">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
          {/* sidebar */}
          <div className="col-span-1 gap-6">
            <div className="flex flex-col gap-6">
              {serviceData.map((data) =>
                selectedService === data.id ? (
                  <div
                    key={data.id}
                    className="flex flex-row cursor-pointer"
                    // onClick={() => setSelectedService(data.id)}
                    onClick={() => navigate(`/services/${data.id}`)}
                  >
                    <div className="px-5 py-4 flex items-center bg-[#2792D0]">
                      <FontAwesomeIcon icon={faChevronRight} color="white" />
                    </div>
                    <div className="px-4 py-4 nav-title bg-[#293742] w-full text-white">
                      <p>{data.title}</p>
                    </div>
                  </div>
                ) : (
                  <div
                    key={data.id}
                    className="flex flex-row cursor-pointer"
                    onClick={() => {
                      // setSelectedService(data.id);
                      navigate(`/services/${data.id}`);

                      window.scrollTo({
                        top: 400,
                        behavior: "smooth",
                      });
                    }}
                  >
                    <div
                      className="px-3 py-4 flex items-center"
                      style={{
                        background: "rgba(39, 146, 208, 0.1)",
                        color: "rgba(133, 142, 150, 1)",
                      }}
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                    <div className="px-4 py-4 nav-title w-full">
                      <p>{data.title}</p>
                    </div>
                  </div>
                )
              )}
              <div className="p-4 follow-section">
                <div className="flex flex-row">
                  <p>Follow Us on</p>
                </div>
                <div className="flex flex-row justify-center items-center h-2/3 gap-6">
                  <div
                    className="p-4 rounded-full"
                    style={{ background: "rgba(44, 46, 51, 0.1)" }}
                  >
                    <a
                      href="https://www.facebook.com/people/SMARTMATRIX-PTE-LTD/100063454840485/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={facebookImg}
                        alt="facebookImg"
                        className="h-[34px]"
                      />
                    </a>
                  </div>
                  <div
                    className="p-4 rounded-full"
                    style={{ background: "rgba(44, 46, 51, 0.1)" }}
                  >
                    <a
                      href="https://www.linkedin.com/company/smartmatrix-pte-ltd/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={linkdinImg}
                        alt="facebookImg"
                        className="h-[34px]"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* content section */}
          <div className="col-span-2">
            {serviceData.map((data, index) =>
              selectedService === data.id ? (
                <>
                  <Helmet>
                    <title>
                      {data.metaTitle ??
                        "SmartMatrix - Providing The Best Services & IT Solutions"}
                    </title>
                    <meta
                      name="description"
                      content={
                        data.metaDescription ??
                        "SmartMatrix offers innovative solutions and services for your creative ideas, led by seasoned professionals in communications and IT."
                      }
                    />
                  </Helmet>

                  <div key={index}>
                    <img
                      src={data.image}
                      alt={data.alt}
                      className="w-full h-[400px] object-cover"
                    />
                    <p className="my-6 details-header">{data.headerTitle}</p>
                    {selectedService === 1 ? (
                      <div className="service-description">
                        <p className="pb-5">
                          Welcome to our suite of full-service where innovation
                          meets efficiency for results. Conquer the digital
                          landscape with our Website, ERP, and SaaS solutions,
                          designed and developed for your business success. Make
                          your operations stronger by leveraging an energetic
                          website, an effective and efficient ERP system, and
                          adaptability of SaaS. From process optimization to
                          collaboration, our integrated solution sets have been
                          designed to empower your business to surge ahead in
                          the digital age. Partner with us in redefining your
                          business journey where technology and strategy
                          converge to drive your enterprise towards phenomenal
                          growth and success.
                        </p>
                        <span>Website Solution & Services</span>
                        <p className="py-5">
                          SmartMatrix offers a gamut of comprehensive website
                          solutions and services for business growth and online
                          presence. Our expert team develops and delivers
                          bespoke, high-performance websites aligned with your
                          strategic objectives. From user-centric design to
                          seamless functionality, we create engaging,
                          responsive, and secure websites that enhance user
                          experience and ensure business success. From vibrant
                          feature-rich eCommerce websites to company websites,
                          to custom web solution needs, scalable and
                          future-ready solutions are offered to empower your
                          brand in the digital space.
                        </p>
                        <span>ERP Solution & Services</span>
                        <p className="py-5">
                          SmartMatrix renders ERP solutions and services that
                          are devised in a way to streamline business operation
                          processes and ultimately drive organizational
                          efficiencies. Our bespoke-designed ERP platforms will
                          integrate core functions of finance, supply chain,
                          human resources, and customer management into one
                          platform for seamless flow and heightened
                          collaboration. As we believe scalability and
                          flexibility form the backbone, we deliver solutions
                          customized to suit every business objective in a
                          manner so adaptive. Our ERP systems enhance data
                          accuracy, optimize workflows, and enable better
                          decision-making. We help organizations boost
                          productivity and reduce operational costs for
                          long-term growth by leveraging advanced technology.
                          From initial consultation to implementation and
                          ongoing maintenance, our end-to-end support ensures
                          that our ERP solutions grow with your business to
                          attain enduring success and operational excellence in
                          an increasingly competitive marketplace.
                        </p>
                        <span>SaaS Solution & Services</span>
                        <p className="py-5">
                          Holistic SaaS (Software as a Service) to offer
                          scalability, flexibility, and cost-effective means of
                          driving operational and bottom-line efficiencies of
                          businesses. It is developed such that our SaaS
                          platforms are ready with third-party integrations
                          using your current on-premises system out of the box,
                          thus opening up services covering everything from
                          Customer Relationship Management Systems to data
                          analytics and automation. We focus on cloud-based
                          solutions which ensure high availability, security,
                          and real-time updates. This will make your business
                          agile, fast, and responsive to the changes in market
                          demands. Our SaaS solution will optimize workflows,
                          help improve collaboration and allow for better
                          decision-making, letting your team focus on what
                          really matters. With SmartMatrix's SaaS solutions, you
                          can reduce IT infrastructure costs, improve
                          operational efficiency, and scale your business
                          without limitations, all while maintaining flexibility
                          and control over your digital tools.
                        </p>
                      </div>
                    ) : selectedService === 2 ? (
                      <div className="service-description">
                        <p className="pb-5">
                          Get transformative with your mobile technology through
                          our bespoke iOS and Android application development
                          services. Our company spearheads innovative creation
                          in mobile applications, which integrates
                          functionality, user experience, and trendy design. The
                          prowess of our skilled team ensures stunning
                          navigation on both iOS and Android platforms to bring
                          your unique vision to life. Be it ideation and design,
                          or development and deployment, we undertake the task
                          with unmatched proficiency. Amplify your digital
                          presence, engage audiences, and drive their attention
                          like never before with our full-spectrum app
                          development services-where creativity meets precision
                          for unparalleled mobile success.
                        </p>
                        <span>IOS Solution & Services</span>
                        <p className="py-5">
                          We provide advanced iOS solutions and services
                          designed to deliver seamless, high-performance mobile
                          applications tailored to the needs of your business.
                          Our team develops innovative iOS apps that are
                          embodiments of functionality, sleek design, and
                          user-centric experiences. From initial consultation
                          and strategy to design, development, and deployment,
                          we ensure each app is optimized for performance,
                          security, and scalability. We use the latest iOS
                          technologies to build applications that have intuitive
                          interfaces and smooth interactions, thus helping
                          businesses communicate with their audience
                          effectively. Be it an enterprise app or a
                          consumer-facing solution, our iOS services ensure
                          enhanced performance and seamless integration with the
                          Apple ecosystem, thus empowering your business to
                          thrive in the digital era.
                        </p>
                        <span>Android Solution & Services</span>
                        <p className="py-5">
                          Custom Android solutions and services are designed and
                          developed to help you with powerful, high-performance
                          mobile applications that ensure the enhancement of
                          your business operations. Our development team is
                          outstanding in developing all sorts of intuitive apps
                          with exceptional functionality, assuring fluidity in
                          the user experience. We focus on making scalable and
                          secure applications, using the latest technologies of
                          Android for performance assurance. Our solutions
                          provide fluency across every touch point through
                          seamless integration with various devices and
                          platforms. Be it enterprise solution development or a
                          customer-facing app, our Android services position
                          businesses to engage users effectively and drive
                          growth.
                        </p>
                      </div>
                    ) : selectedService === 3 ? (
                      <div>
                        <div className="service-description">
                          <p className="pb-5">
                            In today’s digital-first economy, e-commerce is no
                            longer just an option—it’s the cornerstone of modern
                            business strategy. Our E-Commerce Solutions &
                            Services empower businesses to thrive in the
                            competitive online marketplace by offering seamless,
                            scalable, and innovative platforms that deliver
                            exceptional customer experiences and drive growth.
                            We specialize in creating tailored e-commerce
                            ecosystems that align with your business objectives,
                            leveraging cutting-edge technology and a
                            customer-centric approach. Whether you are a
                            start-up launching your first online store or an
                            established enterprise looking to expand, our
                            solutions are designed to elevate your brand and
                            maximize your digital impact.
                          </p>

                          <div className="service-paragraph-title pb-5">
                            Core Features of Our E-Commerce Solutions
                          </div>

                          <div className="pb-5">
                            {[
                              {
                                title: "Custom-Built Online Stores",
                                points: [
                                  "Tailored designs that reflect your brand identity.",
                                  "Intuitive user interfaces for seamless navigation and conversion optimization.",
                                ],
                              },
                              {
                                title: "Secure Payment Gateways",
                                points: [
                                  "Integration of reliable and secure payment solutions to build customer trust.",
                                  "Support for multiple currencies and payment methods for global reach.",
                                ],
                              },
                              {
                                title: "Mobile-Optimized Platforms",
                                points: [
                                  "Fully responsive designs for flawless experiences on any device.",
                                  "Progressive Web Apps (PWAs) for app-like functionality.",
                                ],
                              },
                              {
                                title: "AI-Powered Personalization",
                                points: [
                                  "Deliver customized recommendations based on user behavior and preferences.",
                                  "Enhance customer engagement and loyalty with tailored experiences.",
                                ],
                              },
                              {
                                title: "Advanced Analytics and Reporting",
                                points: [
                                  "Gain actionable insights into customer behavior, sales trends, and marketing ROI.",
                                  "Utilize data-driven strategies to optimize operations and increase revenue.",
                                ],
                              },
                              {
                                title: "Omnichannel Integration",
                                points: [
                                  "Unify online and offline sales channels for a cohesive shopping experience.",
                                  "Enable centralized inventory and customer management.",
                                ],
                              },
                              {
                                title: "Scalable Architecture",
                                points: [
                                  "Platforms designed to grow with your business, handling increased traffic and transactions effortlessly.",
                                  "Flexible solutions that adapt to your evolving needs.",
                                ],
                              },
                              {
                                title: "Robust Security Measures",
                                points: [
                                  "Implementation of SSL encryption, PCI compliance, and data protection protocols.",
                                  "Real-time monitoring and threat detection to safeguard customer information.",
                                ],
                              },
                              {
                                title:
                                  "Streamlined Logistics and Inventory Management",
                                points: [
                                  "Real-time inventory tracking and automated stock updates.",
                                  "Integration with shipping and logistics providers for efficient order fulfillment.",
                                ],
                              },
                              {
                                title: "SEO and Marketing Tools",
                                points: [
                                  "Built-in SEO optimization to enhance online visibility.",
                                  "Tools for email marketing, social media campaigns, and customer retention strategies.",
                                ],
                              },
                            ].map((item, index) => (
                              <ServicesOLUL
                                key={index}
                                index={index + 1}
                                item={item}
                              />
                            ))}
                          </div>
                        </div>

                        <div className="service-paragraph-title pb-5">
                          Business Benefits of Our E-Commerce Solutions
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              level: "Increased Sales",
                              content:
                                "Optimize your online store for conversions and revenue growth.",
                            },
                            {
                              level: "Global Reach",
                              content:
                                "Break geographical barriers and reach a worldwide audience.",
                            },
                            {
                              level: "Enhanced Customer Experience",
                              content:
                                "Provide seamless, user-friendly, and personalized shopping journeys.",
                            },
                            {
                              level: "Data-Driven Decisions",
                              content:
                                "Leverage analytics to refine strategies and improve performance.",
                            },
                            {
                              level: "Cost Efficiency",
                              content:
                                "Streamline operations and reduce overheads with automated processes.",
                            },
                          ].map((item, index) => (
                            <LevelingContent
                              key={index}
                              index={index + 1}
                              item={item}
                              type="ul"
                            />
                          ))}
                        </div>

                        <div className="service-paragraph-title pb-5">
                          Industries We Serve
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              level: "Retail and Fashion",
                              content:
                                "Showcase your products with immersive designs and features.",
                            },
                            {
                              level: "Healthcare",
                              content:
                                "Facilitate online consultations, prescription refills, and health product sales.",
                            },
                            {
                              level: "Food and Beverage",
                              content:
                                "Simplify online ordering and delivery for restaurants and grocers.",
                            },
                            {
                              level: "Electronics and Technology",
                              content:
                                "Build robust platforms for tech-savvy consumers.",
                            },
                            {
                              level: "Wholesale and B2B",
                              content:
                                "Streamline complex transactions with tailored solutions.",
                            },
                          ].map((item, index) => (
                            <LevelingContent
                              key={index}
                              index={index + 1}
                              item={item}
                              type="ul"
                            />
                          ))}
                        </div>

                        <div className="service-paragraph-title pb-5">
                          Why Choose Us?
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              level: "Tailored Approach",
                              content:
                                "Every solution is customized to meet your unique business requirements.",
                            },
                            {
                              level: "Innovative Technology",
                              content:
                                "We utilize the latest tools and frameworks for cutting-edge performance.",
                            },
                            {
                              level: "Comprehensive Support",
                              content:
                                "From ideation to deployment and maintenance, we are with you every step of the way.",
                            },
                            {
                              level: "Proven Expertise",
                              content:
                                "Extensive experience in delivering e-commerce solutions across diverse industries.",
                            },
                            {
                              level: "Customer-Centric Design",
                              content:
                                "Focused on creating experiences that engage and convert.",
                            },
                          ].map((item, index) => (
                            <LevelingContent
                              key={index}
                              index={index + 1}
                              item={item}
                              type="ol"
                            />
                          ))}
                        </div>

                        <div className="service-paragraph-title pb-5">
                          Take Your E-Commerce to the Next Level
                        </div>

                        <div className="gray-point pb-5">
                          The future of retail is digital, and success lies in
                          offering seamless, secure, and engaging shopping
                          experiences. Our E-Commerce Solutions & Services are
                          designed to help businesses unlock their full
                          potential, providing the tools and expertise needed to
                          succeed in the digital marketplace.
                        </div>

                        <div
                          className="gray-point"
                          style={{ color: "#195CA5", fontWeight: "600" }}
                        >
                          Let’s build the future of e-commerce together. Contact
                          us today to start your journey.
                        </div>
                      </div>
                    ) : selectedService === 4 ? (
                      <div className="service-description">
                        <p className="pb-5">
                          SmartMatrix excels in offering advanced UI/UX design
                          services with strategic foresight for user engagement
                          aimed at fostering business growth. Our team of
                          professionals is dedicated to crafting user-friendly,
                          visually stunning interfaces and smooth user
                          experiences to help attain your business objectives.
                          We make it user-centric, wherein every design element
                          adds value and optimizes functionality for a perfect
                          blend of aesthetics and usability.
                        </p>

                        <div className="service-paragraph-title pb-5">
                          Key aspects of our UI/UX design services include:
                        </div>

                        <div
                          className="service-paragraph-title"
                          style={{ color: "#000000" }}
                        >
                          1. User-Centered Design
                        </div>
                        <p className="pb-5">
                          We believe in putting the needs and behaviors of your
                          target audience in the foreground. With thorough
                          research and the construction of user personas, we
                          will ensure every design decision adds to an optimized
                          user journey
                        </p>

                        <div
                          className="service-paragraph-title"
                          style={{ color: "#000000" }}
                        >
                          2. Effortless Navigation
                        </div>
                        <p className="pb-5">
                          Our designs will be all about frictionless, intuitive
                          navigation-a means for users to obtain what they need.
                          We're driven to make things more usable so visitors
                          can quickly get what they came for without
                          frustration.
                        </p>

                        <div
                          className="service-paragraph-title"
                          style={{ color: "#000000" }}
                        >
                          3. Aesthetic and Functional Interfaces
                        </div>
                        <p className="pb-5">
                          We merge imagination with functionality by creating
                          interfaces that are first and foremost great to look
                          at but also functional-where every visual element has
                          a good reason for existence and serves for the sake of
                          enhancing the user's experience.
                        </p>

                        <div
                          className="service-paragraph-title"
                          style={{ color: "#000000" }}
                        >
                          4. Mobile and Web Optimization
                        </div>
                        <p className="pb-5">
                          Our designs will be responsive to ensure the
                          consistency of experiences across devices, from
                          different sizes of screens to anything else. From
                          mobile to desktop, users will interact with your
                          product seamlessly.
                        </p>

                        <div
                          className="service-paragraph-title"
                          style={{ color: "#000000" }}
                        >
                          5. Prototyping and Wireframing
                        </div>
                        <p className="pb-5">
                          We create wireframes and prototypes to visually
                          understand the user flow and layout before actually
                          developing the final product. This helps us to get
                          feedback early and also aligns design concepts with
                          the client's expectations and the users' needs.
                        </p>

                        <div
                          className="service-paragraph-title"
                          style={{ color: "#000000" }}
                        >
                          6. Performance and Speed Optimization
                        </div>
                        <p className="pb-5">
                          We design for performance, ensuring your websites and
                          apps are optimized for speed. Smarter loading will
                          clearly translate into increased user satisfaction and
                          thus greater engagement, lesser bounce rates, and
                          higher conversion rates.
                        </p>

                        <div
                          className="service-paragraph-title"
                          style={{ color: "#000000" }}
                        >
                          7. Brand Consistency
                        </div>
                        <p className="pb-5">
                          We assure the UI/UX design is aligned with your brand
                          identity. From color schemes and typography to the
                          feel of the visuals, we develop designs that enhance
                          your brand presence and connect with users on a deeper
                          level.
                        </p>
                        <p className="pb-5">
                          From designing all the key elements, SmartMatrix's
                          UI/UX design services help businesses craft digital
                          products that give users engaging, satisfying
                          experiences while boosting your business's overall
                          performance.
                        </p>
                      </div>
                    ) : selectedService === 5 ? (
                      <div>
                        <div className="service-description">
                          <p className="pb-5">
                            Scalable system design is the backbone of
                            future-ready technological solutions, built to
                            anticipate growth and adapt to evolving demands. In
                            today’s dynamic digital landscape, scalability is no
                            longer a luxury; it’s a strategic necessity. At
                            SmartMatrix, we prioritize scalable system designs
                            that empower businesses to thrive in an era of
                            boundless opportunities and challenges.
                          </p>

                          <div
                            className="service-paragraph-title pb-5"
                            style={{ color: "#000000" }}
                          >
                            What is Scalability?
                          </div>

                          <p className="pb-5">
                            Scalability refers to a system’s ability to
                            accommodate increasing users, workloads, or data
                            volumes without compromising performance. It ensures
                            that your digital infrastructure evolves seamlessly
                            with your business, adapting to changing
                            requirements and unforeseen demands.
                          </p>

                          <div
                            className="service-paragraph-title pb-5"
                            style={{ color: "#000000" }}
                          >
                            Key Principles of Our Scalable System Design:
                          </div>

                          <div className="black-point">
                            1. Modular Architecture
                          </div>

                          <p className="pb-5">
                            We build systems with modular components that allow
                            for easy extension or replacement. This approach
                            supports incremental growth, minimizing disruptions
                            as your business scales.
                          </p>

                          <div className="black-point">
                            2. Dynamic Resource Optimization
                          </div>

                          <p className="pb-5">
                            Leveraging cloud computing, containerization, and
                            microservices, our designs dynamically allocate
                            resources based on demand. This ensures peak
                            performance while reducing unnecessary costs
                          </p>

                          <div className="black-point">
                            3. Elasticity and Flexibility
                          </div>

                          <p className="pb-5">
                            Our systems scale up during high demand and scale
                            down during slower periods, optimizing both
                            operational efficiency and cost-effectiveness.
                          </p>

                          <div className="black-point">
                            4. Proactive Monitoring and Analytics
                          </div>

                          <p className="pb-5">
                            Real-time insights into performance, user behavior,
                            and potential bottlenecks enable proactive
                            adjustments, ensuring consistent user experiences
                            and uninterrupted business operations.
                          </p>

                          <div
                            className="service-paragraph-title pb-5"
                            style={{ color: "#000000" }}
                          >
                            Business Advantages of Scalable Systems:
                          </div>
                        </div>
                        <div className="pb-5">
                          {[
                            {
                              level: "Agility",
                              content:
                                "Quickly respond to market opportunities or challenges without overhauling your infrastructure.",
                            },
                            {
                              level: "Cost Efficiency",
                              content:
                                "Avoid over-provisioning resources and only pay for what you use.",
                            },
                            {
                              level: "Growth Enablement",
                              content:
                                "Seamlessly accommodate expansion without performance lags or downtime.",
                            },
                            {
                              level: "Competitive Edge",
                              content:
                                "Stay ahead with systems designed to adapt to tomorrow’s challenges.",
                            },
                          ].map((item, index) => (
                            <LevelingContent
                              key={index}
                              index={index + 1}
                              item={item}
                              type="ul"
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Why Choose SmartMatrix?
                        </div>
                        <p className="pb-5 gray-point">
                          At SmartMatrix, we tailor scalable system designs to
                          align with your business objectives, ensuring
                          technology serves as a growth enabler rather than a
                          constraint. Our approach is rooted in innovation and a
                          deep understanding of industry trends, providing you
                          with robust, future-proof solutions. Partner with
                          SmartMatrix to unlock a scalable future where your
                          digital systems grow alongside your aspirations. Let
                          us build the foundation for your sustained success in
                          the ever-evolving digital realm.
                        </p>
                      </div>
                    ) : selectedService === 6 ? (
                      <div>
                        <div className="service-description">
                          <p className="pb-5">
                            In today's fast-moving technological landscape,
                            where digital solutions are driving modern
                            businesses, sustained maintenance and support become
                            cardinal. At SmartMatrix, we believe the deployment
                            of a solution is only the beginning. True value will
                            come from ongoing optimization, support, and
                            maintenance that will keep your digital systems
                            robust, secure, and up to date with the evolution of
                            your business needs.
                          </p>

                          <div
                            className="service-paragraph-title pb-5"
                            style={{ color: "#000000" }}
                          >
                            The Importance of Maintenance and Support
                          </div>

                          <p className="pb-5">
                            Maintenance and support lay the very foundation for
                            the longevity and effectiveness of a digital
                            solution. As businesses grow and technologies
                            advance, systems need to adapt to continuously
                            changing requirements, user expectations, and
                            emerging challenges. The well-cared-for system
                            ensures continuity in operations, increases user
                            satisfaction, and strengthens business resilience.
                          </p>

                          <div
                            className="service-paragraph-title pb-5"
                            style={{ color: "#000000" }}
                          >
                            Proactive, Preventive, and Real-Time Monitoring
                          </div>

                          <p className="pb-5">
                            Our approach to maintenance is based on proactivity.
                            Through advanced monitoring systems, we constantly
                            analyze the health and performance of your digital
                            solutions. We make sure operations run smoothly,
                            disruptions are minimized, and system availability
                            is high by finding problems before they even occur.
                          </p>

                          <div
                            className="service-paragraph-title pb-5"
                            style={{ color: "#000000" }}
                          >
                            Regular Updates and Continuous Improvements
                          </div>

                          <p className="pb-5">
                            The digital world is dynamic, with new updates,
                            security patches, and features coming out almost
                            every other day. SmartMatrix keeps your solutions
                            future-ready with the latest technologies and
                            enhancements. It not only secures your systems but
                            also positions your business to seize new
                            opportunities with confidence.
                          </p>

                          <div
                            className="service-paragraph-title pb-5"
                            style={{ color: "#000000" }}
                          >
                            Security as a Priority
                          </div>

                          <p className="pb-5">
                            In this cybersecurity era, we integrate stringent
                            security protocols within our maintenance services.
                            We perform regular audits, vulnerability
                            assessments, and timely application of security
                            patches to keep your systems safe from all kinds of
                            threats and provide a secure digital environment for
                            your business and stakeholders.
                          </p>

                          <div
                            className="service-paragraph-title pb-5"
                            style={{ color: "#000000" }}
                          >
                            Fast and Effective Issue Resolution
                          </div>

                          <p className="pb-5">
                            Anything can go wrong, and a single minute of
                            downtime ultimately equates to the loss your
                            business faces. For such reasons, our support team
                            is always in readiness for a timely response or
                            quick resolution. Reduce your downtime to its
                            minimum value; protect continuity for your business
                            by smoothly running operations.
                          </p>

                          <div
                            className="service-paragraph-title pb-5"
                            style={{ color: "#000000" }}
                          >
                            Custom Solutions to Fit Unique Needs
                          </div>

                          <p className="pb-5">
                            We know that no two businesses are alike. Our
                            maintenance and support services are designed to
                            align with your unique needs. From continuous
                            support, periodic health checks, to customized
                            service plans, SmartMatrix delivers solutions to
                            meet your unique demands.
                          </p>

                          <div
                            className="service-paragraph-title pb-5"
                            style={{ color: "#000000" }}
                          >
                            Your Strategic Technology Partner
                          </div>

                          <p className="pb-5">
                            With SmartMatrix, you choose a strategic partner who
                            will invest in the success of your digital
                            solutions. Our experts will deeply understand your
                            systems' particularities and advise you in your best
                            interest in a continuously changing digital world.
                            We at SmartMatrix will not only support but also
                            help grow your technology, evolving with your
                            business.
                          </p>

                          <div
                            className="service-paragraph-title pb-5"
                            style={{ color: "#000000" }}
                          >
                            Bring your business to new heights with SmartMatrix
                          </div>

                          <p className="pb-5">
                            More than services, maintenance, and support
                            represent a continued commitment to your success.
                            For working with SmartMatrix, your digital solutions
                            will be always optimized, secure, and ready for the
                            challenges of tomorrow. Let your technology thrive
                            with us so it can really enable business growth and
                            innovation.
                          </p>

                          <p className="pb-5">
                            Ensure reliability, innovation, and excellence with
                            one partner. Choose SmartMatrix-where your success
                            is our mission.
                          </p>
                        </div>
                      </div>
                    ) : selectedService === 7 ? (
                      <div>
                        <div className="service-description">
                          <p className="pb-5">
                            Quality assurance testing is an indispensable
                            strategy in the digital era, where business outcomes
                            and user experiences are highly reliant on software
                            solutions. Quality Assurance testing not only
                            ensures the functionality of a digital product but
                            delivers an outstanding user experience. At
                            SmartMatrix, we specialize in QA testing and
                            automation services to ensure the highest standards
                            of quality, reliability, and performance in your
                            software applications
                          </p>

                          <div
                            className="service-paragraph-title pb-5"
                            style={{ color: "#000000" }}
                          >
                            Precision Assurance at Every Step
                          </div>

                          <p className="pb-5">
                            We believe quality is not an afterthought but an
                            integral part of the software development lifecycle.
                            From every single line of code to functionality and
                            user interactions, it all matters. Our QA team of
                            industry experts uses state-of-the-art methodologies
                            and rigorous testing protocols to ensure that your
                            software solutions operate without a hitch and
                            provide users with flawless experiences.
                          </p>

                          <div
                            className="service-paragraph-title pb-5"
                            style={{ color: "#000000" }}
                          >
                            Full-Cycle QA Services
                          </div>
                          <p className="pb-5">
                            Our approach to QA testing is comprehensive and
                            embraces all the important aspects of your software:
                          </p>
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              level: "Functional Testing",
                              content:
                                "It confirms that all the features work as expected and meet the specified requirements.",
                            },
                            {
                              level: "Performance Testing",
                              content:
                                " It evaluates the scalability and responsiveness of your applications under various user loads and conditions.",
                            },
                            {
                              level: "Security Testing",
                              content:
                                " It identifies vulnerabilities and strengthens your systems against potential cyber threats.",
                            },
                            {
                              level: "Usability Testing",
                              content:
                                " It enhances user satisfaction by ensuring that applications are intuitive, accessible, and easy to use.",
                            },
                          ].map((item, index) => (
                            <LevelingContent
                              key={index}
                              index={index + 1}
                              item={item}
                              type="ol"
                            />
                          ))}

                          <div
                            className="service-paragraph-title py-5"
                            style={{ color: "#000000" }}
                          >
                            The Power of QA Automation
                          </div>

                          <p className="gray-point pb-5">
                            Automation revolutionizes traditional testing by
                            making it faster, more effective, and accurate. In
                            SmartMatrix, we adopt innovative automation
                            frameworks that:
                          </p>

                          <div className="pl-2 pb-5">
                            <div className="flex gap-1 gray-point">
                              <span className="h-[22px] flex items-center pr-1">
                                <span className="h-[4px] w-[4px] rounded-full bg-[#858e96]"></span>
                              </span>
                              Accelerate test cycles and reduce time-to-market.
                            </div>

                            <div className="flex gap-1 gray-point">
                              <span className="h-[22px] flex items-center pr-1">
                                <span className="h-[4px] w-[4px] rounded-full bg-[#858e96]"></span>
                              </span>
                              Achieve consistent, repeatable results of test
                              cases.
                            </div>

                            <div className="flex gap-1 gray-point">
                              <span className="h-[22px] flex items-center pr-1">
                                <span className="h-[4px] w-[4px] rounded-full bg-[#858e96]"></span>
                              </span>
                              Enable continuous testing in agile and DevOps
                              environments.
                            </div>
                          </div>

                          <p className="gray-point pb-5">
                            Our customized automation solutions will focus on
                            scenarios of high business impact, enabling rapid
                            defect detection and ensuring your software
                            continuously improves without sacrificing quality.
                          </p>

                          <div
                            className="service-paragraph-title pb-5"
                            style={{ color: "#000000" }}
                          >
                            Agile Development Support
                          </div>

                          <p className="gray-point pb-5">
                            In today's development cycles, things move fast, and
                            continuous integration and deployment are the norms.
                            Our QA automation fits seamlessly into agile
                            workflows, enabling quick iterations without
                            compromising on quality. This agility will enable
                            your team to innovate with confidence, knowing full
                            well that quality is assured at every stage.
                          </p>

                          <div
                            className="service-paragraph-title pb-5"
                            style={{ color: "#000000" }}
                          >
                            Beyond Testing: Delivering Business Value
                          </div>

                          <p className="gray-point pb-5">
                            At SmartMatrix, QA services are designed to deliver
                            more than defect-free software; the company is out
                            to enhance your business outcomes. By finding
                            potential issues proactively, our testing minimizes
                            disruptions, reduces costs, and improves user
                            satisfaction. Our insights contribute toward
                            optimized workflows, strategic enhancements, and
                            solutions that align with your business goals.
                          </p>

                          <div
                            className="service-paragraph-title pb-5"
                            style={{ color: "#000000" }}
                          >
                            Collaborative and Customized Approach
                          </div>

                          <p className="gray-point pb-5">
                            We don't test your software; we work with you to
                            understand the exact needs and problems. Our group
                            works in very close conjunction with your
                            developers, offering constructive insight and thus
                            making strategic integration of QA activities with
                            project goals. This will surely mean that our
                            solutions add value to your development process.
                          </p>

                          <div
                            className="service-paragraph-title pb-5"
                            style={{ color: "#000000" }}
                          >
                            Why SmartMatrix for QA Testing?
                          </div>

                          <div className="pl-2 pb-5">
                            <div className="flex gap-1 gray-point">
                              <span className="h-[22px] flex items-center pr-1">
                                <span className="h-[4px] w-[4px] rounded-full bg-[#858e96]"></span>
                              </span>
                              Expertise in advanced testing methodologies and
                              tools.
                            </div>

                            <div className="flex gap-1 gray-point">
                              <span className="h-[22px] flex items-center pr-1">
                                <span className="h-[4px] w-[4px] rounded-full bg-[#858e96]"></span>
                              </span>
                              Solutions aligned to business needs.
                            </div>

                            <div className="flex gap-1 gray-point">
                              <span className="h-[22px] flex items-center pr-1">
                                <span className="h-[4px] w-[4px] rounded-full bg-[#858e96]"></span>
                              </span>
                              Proactive, preventive, and user-centric approach.
                            </div>

                            <div className="flex gap-1 gray-point">
                              <span className="h-[22px] flex items-center pr-1">
                                <span className="h-[4px] w-[4px] rounded-full bg-[#858e96]"></span>
                              </span>
                              Commitment to superior quality and business
                              impact.
                            </div>
                          </div>

                          <div
                            className="service-paragraph-title pb-5"
                            style={{ color: "#000000" }}
                          >
                            Partner with SmartMatrix for QA Excellence
                          </div>

                          <p className="gray-point pb-5">
                            Quality Assurance is not just a process, it's a
                            promise. With SmartMatrix, you get a partner
                            committed to taking your software to unmatched
                            levels of quality, reliability, and end-user
                            satisfaction. Let us help you build trust, drive
                            engagement, and realize your business goals through
                            precision-driven QA and automation.
                          </p>

                          <p className="gray-point pb-5">
                            Choose excellence, choose SmartMatrix, where digital
                            success is fueled by robust testing and innovative
                            strategies.
                          </p>
                        </div>
                      </div>
                    ) : selectedService === 8 ? (
                      <div>
                        <div className="service-description">
                          <p className="pb-5">
                            In the fast-paced landscape of digital innovation,
                            cloud technology provides the core architecture that
                            has empowered scalability, efficiency, and modern
                            business innovations. Cloud services give an
                            organization the capability to adapt dynamically
                            with market demand, boost productivity, and reduce
                            operational costs. Each of our solutions is
                            developed at the edge to give your business the
                            dexterity and vigor to sail smoothly in this
                            competitive era.
                          </p>
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Unlock the Potential of Cloud
                        </div>

                        <p className="gray-point pb-5">
                          Cloud is more than a move in technology; it is about
                          strategic, business transformational enablement. Our
                          tailored cloud solutions will empower you to do the
                          follow
                        </p>

                        <div className="pb-5">
                          {[
                            {
                              level: "Speed Up Innovation",
                              content:
                                "Leverage the latest cloud technologies and bring new offerings to market faster",
                            },
                            {
                              level: "Engage in Smarter Collaboration",
                              content:
                                "Give teams seamless access to data and tools from anywhere at any time.",
                            },
                            {
                              level: "Reduce Costs",
                              content:
                                "Move away from traditional IT infrastructure to flexible, pay-as-you-go models.",
                            },
                            {
                              level: "Scale Effortlessly",
                              content:
                                "Keep pace with dynamic demand with resources and infrastructure that scale on demand.",
                            },
                          ].map((item, index) => (
                            <LevelingContent
                              key={index}
                              index={index + 1}
                              item={item}
                              type="ul"
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Full Cloud Service Solutions
                        </div>

                        <p className="gray-point pb-5">
                          Our services range from providing all kinds of cloud
                          services to suit varied business needs, including:
                        </p>

                        <div className="black-point">
                          1. Cloud Infrastructure as a Service (IaaS)
                        </div>
                        <div className="gray-point pb-5">
                          Effortlessly create and manage virtualized computing
                          resources. Our IaaS solutions come with on-demand
                          infrastructure, which reduces the need for expensive
                          on-premise hardware while guaranteeing high
                          availability and performance.
                        </div>

                        <div className="black-point">
                          2. Platform as a Service (PaaS)
                        </div>
                        <div className="gray-point pb-5">
                          Accelerate application development with our PaaS
                          solutions. We offer prebuilt platforms that rapidly
                          organize the development lifecycle so your people can
                          focus on innovation, not infrastructure management.
                        </div>

                        <div className="black-point">
                          3. Software as a Service (SaaS)
                        </div>
                        <div className="gray-point pb-5">
                          Transform your business the easy way with our SaaS
                          offerings. Powerful applications are at your
                          fingertips over the Internet, with no installation,
                          maintenance, or upgrade headaches.
                        </div>

                        <div className="black-point">
                          4. Cloud Migration Services
                        </div>
                        <div className="gray-point pb-5">
                          Migrate your business securely into the cloud and with
                          a minimum of hassle. From assessment and strategy
                          development to execution, our professionals cover all
                          migration tasks and guarantee minimum disturbance
                          while assuring peak efficiency
                        </div>

                        <div className="black-point">
                          5. Hybrid and Multi-Cloud Solutions
                        </div>
                        <div className="gray-point pb-5">
                          Have the perfect blend of flexibility and control with
                          Hybrid and Multi-cloud solutions. Combine the best
                          from public and private clouds and implement your
                          tailor-made ecosystem as per business needs.
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Security and Compliance First
                        </div>

                        <p className="gray-point pb-5">
                          In the cloud, security cannot be compromised. Our
                          solutions weave in industry-leading security
                          attributes, including encryption, access controls, and
                          continuous monitoring, to help protect your data and
                          applications. We also ensure compliance with regional
                          and industry-specific regulations, giving you peace of
                          mind while you scale.
                        </p>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Business-Centric Benefits
                        </div>

                        <p className="gray-point pb-5">
                          By using our services, you will accrue the following
                          advantages:
                        </p>

                        <div className="pb-5">
                          {[
                            {
                              level: "Agility",
                              content:
                                "Quickly adapt to evolving business needs with scalable resources.",
                            },
                            {
                              level: "Resilience",
                              content:
                                "Make sure business continuity is maintained via disaster recovery and backup solutions.",
                            },
                            {
                              level: "Insights",
                              content:
                                "Leverage cloud-based analytics to make data-driven decisions.",
                            },
                            {
                              level: "Competitive Edge",
                              content:
                                "Stay ahead of the competition with innovative technology.",
                            },
                          ].map((item, index) => (
                            <LevelingContent
                              key={index}
                              index={index + 1}
                              item={item}
                              type="ul"
                            />
                          ))}
                        </div>

                        <p className="gray-point pb-5">
                          Right at the core of our cloud services is a
                          commitment to your success. Our people work with you
                          to understand your specific needs, designing and
                          implementing solutions aligned with your strategic
                          objectives.
                        </p>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Choose the Cloud; Choose Transformation
                        </div>

                        <p className="gray-point pb-5">
                          Cloud is no longer the future but the present.
                          Embracing the cloud positions your business for
                          growth, agility, and success in a world going
                          increasingly digital. Let us help your business tap
                          into the complete power of cloud services to achieve
                          more.
                        </p>

                        <div
                          className="gray-point"
                          style={{ color: "#195CA5", fontWeight: "600" }}
                        >
                          Experience innovation, flexibility, and scalability
                          with our Cloud Services and Solutions. Let’s build a
                          smarter, more connected future together.
                        </div>
                      </div>
                    ) : selectedService === 9 ? (
                      <div>
                        <div className="service-description">
                          <p className="pb-5">
                            Smart cards represent a secure, efficient, and
                            versatile technology that revolutionizes data
                            management, authentication, and transaction
                            processes. Whether you're aiming to enhance
                            security, streamline operations, or provide a
                            seamless user experience, our smart card solutions
                            are designed to meet the unique needs of modern
                            enterprises.
                          </p>

                          <div
                            className="service-paragraph-title pb-5"
                            style={{ color: "#000000" }}
                          >
                            What Are Smart Cards?
                          </div>

                          <p className="gray-point pb-5">
                            Smart cards are pocket-sized devices embedded with
                            integrated circuits, capable of storing and
                            processing data securely. They are used across
                            industries for applications ranging from secure
                            identification and access control to payment systems
                            and loyalty programs.
                          </p>
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Key Features of Our Smart Card Solutions
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              title: "Enhanced Security",
                              points: [
                                "Smart cards leverage advanced encryption and authentication technologies to protect sensitive information.",
                                "Ideal for secure logins, payment authentication, and access control.",
                              ],
                            },
                            {
                              title: "Customizable Solutions",
                              points: [
                                "Tailored to your specific business needs, including chip design, card materials, and application integration.",
                                "Supports contact, contactless, and hybrid functionalities for diverse use cases.",
                              ],
                            },
                            {
                              title: "Seamless Integration",
                              points: [
                                "Compatible with existing systems, ensuring smooth implementation with minimal disruption.",
                                "Integrates with databases, mobile apps, and cloud-based platforms.",
                              ],
                            },
                            {
                              title: "Durability and Reliability",
                              points: [
                                "Designed to withstand regular use in various environments, ensuring long-term functionality.",
                                "Compliant with international standards for quality and performance.",
                              ],
                            },
                          ].map((item, index) => (
                            <ServicesOLUL
                              key={index}
                              index={index + 1}
                              item={item}
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Applications of Smart Cards
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              title: "Identity Management",
                              points: [
                                "Streamline employee or customer identification with secure smart card solutions.",
                                "Used for access control, digital signatures, and multi-factor authentication.",
                              ],
                            },
                            {
                              title: "Payment Systems",
                              points: [
                                "Enable secure and contactless payments for faster and safer transactions.",
                                "Used in banking, retail, and public transport.",
                              ],
                            },
                            {
                              title: "Healthcare",
                              points: [
                                "Store and access patient records securely, enhancing data accuracy and confidentiality.",
                                "Simplify medical billing and insurance processes.",
                              ],
                            },
                            {
                              title: "Loyalty and Membership Programs",
                              points: [
                                "Enhance customer engagement by integrating loyalty points, rewards, and personalized offers.",
                                "Build brand loyalty with seamless customer experiences.",
                              ],
                            },
                            {
                              title: "Government and Public Sector",
                              points: [
                                "Ideal for national ID cards, driver’s licenses, and voter identification systems.",
                                "Facilitates secure and efficient public services.",
                              ],
                            },
                          ].map((item, index) => (
                            <ServicesOLUL
                              key={index}
                              index={index + 1}
                              item={item}
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Business Benefits of Smart Cards
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              level: "Increased Security",
                              content:
                                "Protects against fraud, counterfeiting, and unauthorized access.",
                            },
                            {
                              level: "Operational Efficiency",
                              content:
                                "Streamlines workflows and reduces administrative overhead.",
                            },
                            {
                              level: "Improved User Experience",
                              content:
                                "Offers faster, easier, and more convenient interactions.",
                            },
                            {
                              level: "Scalability",
                              content:
                                "Adaptable to future needs as your business grows.",
                            },
                          ].map((item, index) => (
                            <LevelingContent
                              key={index}
                              index={index + 1}
                              item={item}
                              type="ul"
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Why Choose Our Smart Card Solutions?
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              level: "Expertise",
                              content:
                                "A team of seasoned professionals with a deep understanding of smart card technology.",
                            },
                            {
                              level: "Innovation",
                              content:
                                "State-of-the-art solutions designed to keep you ahead of the curve.",
                            },
                            {
                              level: "Customer-Centric Approach",
                              content:
                                "Tailored services that align with your business goals and challenges.",
                            },
                          ].map((item, index) => (
                            <LevelingContent
                              key={index}
                              index={index + 1}
                              item={item}
                              type="ul"
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Future-Ready Solutions
                        </div>

                        <p className="gray-point pb-5">
                          Smart cards are a gateway to enhanced digital
                          experiences and secure operations. As technology
                          evolves, our solutions are built to grow with your
                          business, ensuring long-term value and success.
                        </p>

                        <div
                          className="gray-point"
                          style={{ color: "#195CA5", fontWeight: "600" }}
                        >
                          Unlock the potential of smart card technology with our
                          innovative solutions. Let’s redefine security,
                          efficiency, and user engagement together.
                        </div>
                      </div>
                    ) : selectedService === 10 ? (
                      <div>
                        <p className="gray-point pb-5">
                          In today’s fast-paced digital landscape, robust IT
                          hardware and server solutions are the backbone of
                          operational efficiency and business success. From
                          ensuring seamless data flow to supporting critical
                          applications, our IT hardware and server solutions are
                          tailored to meet the unique needs of modern
                          enterprises, enabling scalability, performance, and
                          reliability.
                        </p>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Comprehensive IT Hardware Solutions
                        </div>

                        <p className="gray-point pb-5">
                          We offer a wide range of IT hardware solutions
                          designed to equip your business with the tools it
                          needs to thrive in a competitive market.
                        </p>

                        <div className="pb-5">
                          {[
                            {
                              title: "Desktops and Workstations",
                              points: [
                                "High-performance devices tailored for business operations, creative professionals, and technical teams.",
                                "Configurable to meet specific requirements, ensuring optimal productivity and user satisfaction.",
                              ],
                            },
                            {
                              title: "Laptops and Mobile Devices",
                              points: [
                                "Lightweight, powerful, and versatile devices for on-the-go productivity.",
                                "Integrated with advanced security features for safe and flexible remote work.",
                              ],
                            },
                            {
                              title: "Networking Equipment",
                              points: [
                                "Switches, routers, firewalls, and access points to ensure seamless connectivity and robust security.",
                                "Designed to handle high-speed data transfer and support growing network demands.",
                              ],
                            },
                            {
                              title: "Peripherals and Accessories",
                              points: [
                                "Printers, scanners, monitors, and other accessories to complete your IT setup.",
                                "Ensures a streamlined and efficient workplace environment.",
                              ],
                            },
                          ].map((item, index) => (
                            <ServicesOLUL
                              key={index}
                              index={index + 1}
                              item={item}
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Advanced Server Solutions
                        </div>

                        <p className="gray-point pb-5">
                          Our server solutions are engineered to power your
                          enterprise’s data-driven needs with unparalleled
                          efficiency and reliability.
                        </p>

                        <div className="pb-5">
                          {[
                            {
                              title: "Dedicated Servers",
                              points: [
                                "Ideal for businesses requiring full control, high performance, and enhanced security.",
                                "Custom-built to match your unique business workloads and operational demands.",
                              ],
                            },
                            {
                              title: "Cloud Servers",
                              points: [
                                "Flexible, scalable, and cost-effective solutions for businesses looking to leverage cloud computing.",
                                "Provides on-demand resources, minimizing upfront costs and enabling rapid scaling.",
                              ],
                            },
                            {
                              title: "Hybrid Server Solutions",
                              points: [
                                "A seamless blend of on-premises and cloud servers for maximum flexibility and performance.",
                                "Ensures secure data management with the benefits of cloud scalability.",
                              ],
                            },
                            {
                              title: "Storage Servers",
                              points: [
                                "High-capacity storage solutions designed for data-heavy applications, backups, and archiving.",
                                "Includes NAS (Network Attached Storage) and SAN (Storage Area Network) configurations.",
                              ],
                            },
                          ].map((item, index) => (
                            <ServicesOLUL
                              key={index}
                              index={index + 1}
                              item={item}
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Why Choose Our IT Hardware and Server Solutions?
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              title: "Performance and Reliability",
                              points: [
                                "High-quality hardware and servers designed for 24/7 operation with minimal downtime.",
                              ],
                            },
                            {
                              title: "Custom Solutions",
                              points: [
                                "Tailored to meet your business needs, ensuring the perfect balance of performance and cost-efficiency.",
                              ],
                            },
                            {
                              title: "Scalability",
                              points: [
                                "Solutions that grow with your business, accommodating increasing workloads and future demands.",
                              ],
                            },
                            {
                              title: "Comprehensive Support",
                              points: [
                                "End-to-end support, including installation, configuration, and ongoing maintenance.",
                              ],
                            },
                            {
                              title: "Security-First Approach",
                              points: [
                                "Hardware and server solutions equipped with advanced security features to safeguard your data.",
                              ],
                            },
                          ].map((item, index) => (
                            <ServicesOLUL
                              key={index}
                              index={index + 1}
                              item={item}
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Business Benefits
                        </div>

                        <div className="pl-2 pb-5">
                          <div className="flex gap-1 gray-point">
                            <span className="h-[22px] flex items-center pr-1">
                              <span className="h-[4px] w-[4px] rounded-full bg-[#858e96]"></span>
                            </span>
                            Optimized Operations: Streamline processes with
                            hardware and servers designed to handle critical
                            tasks effortlessly.
                          </div>

                          <div className="flex gap-1 gray-point">
                            <span className="h-[22px] flex items-center pr-1">
                              <span className="h-[4px] w-[4px] rounded-full bg-[#858e96]"></span>
                            </span>
                            Enhanced Productivity: Equip your workforce with
                            tools and systems that boost efficiency and
                            collaboration.
                          </div>

                          <div className="flex gap-1 gray-point">
                            <span className="h-[22px] flex items-center pr-1">
                              <span className="h-[4px] w-[4px] rounded-full bg-[#858e96]"></span>
                            </span>
                            Cost-Effectiveness: Minimize downtime and
                            operational risks with reliable infrastructure.
                          </div>

                          <div className="flex gap-1 gray-point">
                            <span className="h-[22px] flex items-center pr-1">
                              <span className="h-[4px] w-[4px] rounded-full bg-[#858e96]"></span>
                            </span>
                            Future-Ready Technology: Stay ahead of the curve
                            with scalable solutions that adapt to evolving
                            business needs.
                          </div>
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Partner with Us for IT Success
                        </div>

                        <p className="gray-point pb-5">
                          Whether you’re a startup, an SME, or a large
                          enterprise, our IT hardware and server solutions
                          provide the foundation for your digital
                          transformation. From consultation to deployment and
                          beyond, we’re committed to delivering solutions that
                          empower your business to thrive in the digital era.
                        </p>

                        <div
                          className="gray-point"
                          style={{ color: "#195CA5", fontWeight: "600" }}
                        >
                          Let’s build your IT infrastructure for today and
                          tomorrow. Reach out to us to discuss your hardware and
                          server needs.
                        </div>
                      </div>
                    ) : selectedService === 11 ? (
                      <div>
                        <p className="gray-point pb-5">
                          In a rapidly evolving technological landscape,
                          one-size-fits-all solutions no longer suffice.
                          Customized electronic device solutions empower
                          businesses to achieve specific goals with
                          precision-engineered devices that align with their
                          unique needs and challenges. Our expertise lies in
                          delivering tailor-made electronic solutions that drive
                          efficiency, enhance user experience, and provide a
                          competitive edge.
                        </p>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Customized Electronic Devices Designed for Your Needs
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              title: "Application-Specific Devices",
                              points: [
                                "Devices designed to meet specific business functions, such as handheld inventory scanners, payment terminals, or monitoring equipment.",
                                "Engineered for industry-specific requirements in sectors like healthcare, retail, manufacturing, and logistics.",
                              ],
                            },
                            {
                              title: "Smart IoT Devices",
                              points: [
                                "Development of Internet of Things (IoT) devices that connect seamlessly within your ecosystem.",
                                "Includes sensors, trackers, and controllers that enable real-time data collection and automation.",
                              ],
                            },
                            {
                              title: "Wearable Technology",
                              points: [
                                "Custom-built wearable devices for applications in health monitoring, field operations, or customer engagement.",
                                "Features like biometric tracking, GPS, and seamless connectivity for enhanced usability.",
                              ],
                            },
                            {
                              title: "Embedded Systems",
                              points: [
                                "Integration of embedded hardware and software to create specialized devices for unique applications.",
                                "Ideal for automation, robotics, and industrial control systems.",
                              ],
                            },
                            {
                              title: "Consumer-Focused Devices",
                              points: [
                                "Development of custom consumer electronics like smart home devices, personal gadgets, and specialized equipment.",
                                "Combines innovative design with advanced functionality to enhance user appeal.",
                              ],
                            },
                          ].map((item, index) => (
                            <ServicesOLUL
                              key={index}
                              index={index + 1}
                              item={item}
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Key Features of Our Customized Solutions
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              title: "Scalability and Flexibility",
                              points: [
                                "Devices designed to grow with your business needs, allowing for easy upgrades and adaptations.",
                              ],
                            },
                            {
                              title: "Enhanced Performance",
                              points: [
                                "High-quality components and tailored engineering ensure optimal performance under specific conditions.",
                              ],
                            },
                            {
                              title: "User-Centric Design",
                              points: [
                                "Devices are developed with a focus on ergonomics, ease of use, and seamless integration into workflows.",
                              ],
                            },
                            {
                              title: "Robust Security",
                              points: [
                                "Built-in features to safeguard data and ensure secure communication between devices.",
                              ],
                            },
                            {
                              title: "Energy Efficiency",
                              points: [
                                "Devices optimized for low power consumption to reduce operational costs and enhance sustainability.",
                              ],
                            },
                          ].map((item, index) => (
                            <ServicesOLUL
                              key={index}
                              index={index + 1}
                              item={item}
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Business Benefits
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              level: "Increased Efficiency",
                              content: [
                                "Devices tailored to specific functions improve productivity and streamline operations.",
                              ],
                            },
                            {
                              level: "Cost-Effectiveness",
                              content: [
                                "Minimized resource wastage with solutions aligned to your exact requirements.",
                              ],
                            },
                            {
                              level: "Enhanced Competitiveness",
                              content: [
                                "Stand out in the market with cutting-edge, purpose-built technology.",
                              ],
                            },
                            {
                              level: "Faster Time-to-Market",
                              content: [
                                "Rapid development and deployment of customized solutions to keep up with dynamic market demands.",
                              ],
                            },
                          ].map((item, index) => (
                            <LevelingContent
                              key={index}
                              index={index + 1}
                              item={item}
                              type="ul"
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Industries We Serve
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              level: "Healthcare",
                              content: [
                                "Patient monitoring devices, diagnostic tools, and medical IoT solutions.",
                              ],
                            },
                            {
                              level: "Retail",
                              content: [
                                "POS systems, smart inventory trackers, and customer engagement devices.",
                              ],
                            },
                            {
                              level: "Manufacturing",
                              content: [
                                "Automation equipment, sensors, and industrial IoT devices.",
                              ],
                            },
                            {
                              level: "Logistics",
                              content: [
                                "GPS trackers, fleet management devices, and automated warehousing solutions.",
                              ],
                            },
                            {
                              level: "Smart Cities",
                              content: [
                                "Custom IoT devices for urban development, such as smart meters and surveillance systems.",
                              ],
                            },
                          ].map((item, index) => (
                            <LevelingContent
                              key={index}
                              index={index + 1}
                              item={item}
                              type="ul"
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Why Choose Us?
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              title: "Expertise in Customization",
                              points: [
                                "Decades of experience in delivering innovative, client-specific electronic solutions.",
                              ],
                            },
                            {
                              title: "End-to-End Development",
                              points: [
                                "From concept to deployment, we provide comprehensive design, prototyping, testing, and production services.",
                              ],
                            },
                            {
                              title: "Collaborative Approach",
                              points: [
                                "Close partnership with clients to ensure the final product meets all functional and aesthetic requirements.",
                              ],
                            },
                            {
                              title: "Reliable Support",
                              points: [
                                "Post-deployment support for maintenance, updates, and scalability to keep your devices running flawlessly.",
                              ],
                            },
                          ].map((item, index) => (
                            <ServicesOLUL
                              key={index}
                              index={index + 1}
                              item={item}
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Partner with Us for Cutting-Edge Custom Devices
                        </div>

                        <p className="gray-point pb-5">
                          Transform your business operations with electronic
                          devices tailored to your vision. Whether you’re
                          looking to enhance operational efficiency, deliver
                          superior customer experiences, or pioneer new
                          technology, our customized solutions are here to turn
                          your ideas into reality.
                        </p>

                        <div
                          className="gray-point"
                          style={{ color: "#195CA5", fontWeight: "600" }}
                        >
                          Contact us today to discuss your customized electronic
                          device requirements and take the first step toward
                          innovation.
                        </div>
                      </div>
                    ) : selectedService === 12 ? (
                      <div>
                        <p className="gray-point pb-5">
                          Efficiency, accuracy, and real-time tracking of data
                          are no longer options but a necessity in today's
                          fast-paced business environment. RFID and barcode
                          solutions from their end significantly empower
                          businesses to manage operations efficiently, improve
                          inventory management, and enhance customer
                          experiences. Our advanced RFID and barcode solutions
                          cater specifically to the needs of different
                          industries and easily integrate into your existing
                          workflows for real, quantifiable results.
                        </p>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Why Choose RFID and Barcode Technology?
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              title: "Precision Tracking",
                              points: [
                                "Automate data capture, eliminating manual errors to track assets, products, and resources with complete accuracy.",
                              ],
                            },
                            {
                              title: "Real-Time Visibility",
                              points: [
                                "Get real-time information on inventory, assets, or shipments to make better decisions and take operational control.",
                              ],
                            },
                            {
                              title: "Operational Efficiency",
                              points: [
                                "Accelerate processes that include inventory counts, tracking of assets, and point-of-sale transactions using fast and reliable scans.",
                              ],
                            },
                            {
                              title: "Cost-Effective",
                              points: [
                                "Reduce labor costs and time spent on manual data entry and inventory management.",
                              ],
                            },
                          ].map((item, index) => (
                            <ServicesOLUL
                              key={index}
                              index={index + 1}
                              item={item}
                            />
                          ))}
                        </div>
                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Comprehensive RFID and Barcode Solutions
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              title: "Inventory Management Systems",
                              points: [
                                "Employ RFID or barcode-enabled systems for proper and timely tracking of inventory.",
                                "Real-time updating of stock levels to avoid overstocking or stockouts.",
                              ],
                            },
                            {
                              title: "Asset Tracking Solution",
                              points: [
                                "Location, condition, and utilization of key assets tracked by RFID and barcode tags.",
                                "Operational control is enhanced while asset loss is reduced.",
                              ],
                            },
                            {
                              title: "Point-of-Sale (POS) Integration",
                              points: [
                                "Improve customer checkout processes by introducing barcode-driven POS systems.",
                                "Speed up the velocity of transactions to enable increased customer experiences.",
                              ],
                            },
                            {
                              title: "Warehouse and Logistics Optimization",
                              points: [
                                "Automate processes such as order picking, packing, and shipping in the warehouse using RFID and barcode assistance.",
                                "Real-time shipment tracking to improve supply chain visibility and efficiency.",
                              ],
                            },
                            {
                              title: "Access Control and Authentication",
                              points: [
                                "RFID can be used in access control systems to provide security through employee ID cards or event passes.",
                                "Barcode solutions for events, transportation, and other forms of ticketing.",
                              ],
                            },
                            {
                              title: "Healthcare Applications",
                              points: [
                                "Employ RFID or barcode for patient records, medication, and equipment management to enhance their care and improve compliance.",
                              ],
                            },
                          ].map((item, index) => (
                            <ServicesOLUL
                              key={index}
                              index={index + 1}
                              item={item}
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Key Features of Our RFID/Barcode Solutions
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              title: "Customizable Systems",
                              points: [
                                "Solutions designed for your industry, scale, and specific requirements.",
                              ],
                            },
                            {
                              title: "Seamless Integration",
                              points: [
                                "Software, ERP, and hardware integration made easy.",
                              ],
                            },
                            {
                              title: "Scalable Design",
                              points: [
                                "Solutions that can grow with your business, flexible to your needs.",
                              ],
                            },
                            {
                              title: "Enhanced Durability",
                              points: [
                                "High-quality RFID tags and barcode labels for various environments, even the harshest conditions.",
                              ],
                            },
                            {
                              title: "Advanced Analytics",
                              points: [
                                "Comprehensive reporting tools to analyze trends, improve processes, and support strategic decisions.",
                              ],
                            },
                          ].map((item, index) => (
                            <ServicesOLUL
                              key={index}
                              index={index + 1}
                              item={item}
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Benefits for Your Business
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              level: "Improved Accuracy",
                              content: [
                                "Lessen manual data collection and processing errors.",
                              ],
                            },
                            {
                              level: "Increased Productivity",
                              content: [
                                "Automate time-intensive, manual activities.",
                              ],
                            },
                            {
                              level: "Better Customer Experience",
                              content: [
                                "Speedy checkout, precision in service delivery.",
                              ],
                            },
                            {
                              level: "Cost Savings",
                              content: [
                                "Decrease in labor costs and better resource utilization.",
                              ],
                            },
                            {
                              level: "Enhanced Security",
                              content: [
                                "Monitoring and controlling assets to reduce loss and theft.",
                              ],
                            },
                          ].map((item, index) => (
                            <LevelingContent
                              key={index}
                              index={index + 1}
                              item={item}
                              type="ul"
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Industries We Serve
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              level: "Retail",
                              content: [
                                "Simplify inventory management and streamline checkout processes.",
                              ],
                            },
                            {
                              level: "Logistics and Supply Chain",
                              content: [
                                "Optimize warehousing and enhance shipment tracking.",
                              ],
                            },
                            {
                              level: "Healthcare",
                              content: [
                                "Improve patient care and track medical equipment.",
                              ],
                            },
                            {
                              level: "Manufacturing",
                              content: [
                                "Ensure precise tracking of raw materials and finished goods.",
                              ],
                            },
                            {
                              level: "Education",
                              content: [
                                "Manage libraries, student attendance, and access control.",
                              ],
                            },
                          ].map((item, index) => (
                            <LevelingContent
                              key={index}
                              index={index + 1}
                              item={item}
                              type="ul"
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Why Partner with Us?
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              title:
                                "Expertise in RFID and Barcode Technologies",
                              points: [
                                "Proven track record of delivering successful implementations across industries.",
                              ],
                            },
                            {
                              title: "Tailored Solutions",
                              points: [
                                "Catered specifically to your operation's needs and your business goals in mind.",
                              ],
                            },
                            {
                              title: "All-Inclusive Service Support",
                              points: [
                                "We provide service from the consultation and design phases to full implementation, with ongoing support.",
                              ],
                            },
                            {
                              title: "Future-Ready Technology",
                              points: [
                                "Scalable solutions to meet growing technological needs and expanding businesses.",
                              ],
                            },
                          ].map((item, index) => (
                            <ServicesOLUL
                              key={index}
                              index={index + 1}
                              item={item}
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Let’s Innovate Together
                        </div>

                        <p className="gray-point pb-5">
                          Revolutionize your business operations with advanced
                          RFID and barcode solutions designed for the future.
                          SmartMatrix takes a holistic approach by integrating
                          RFID and Barcode solutions for clients who require the
                          combined benefits of both technologies. Enhance
                          accuracy, efficiency, and visibility across your
                          workflows with our cutting-edge technology.
                        </p>

                        <div
                          className="gray-point"
                          style={{ color: "#195CA5", fontWeight: "600" }}
                        >
                          Contact us today to explore how RFID and barcode
                          solutions can transform your business.
                        </div>
                      </div>
                    ) : selectedService === 13 ? (
                      <div>
                        <p className="gray-point pb-5">
                          In the ever-evolving world of technology, the line
                          between industrial innovation and home convenience
                          continues to blur. Our Industrial and Home Solutions
                          are designed to bridge this gap, bringing advanced
                          technology and tailored solutions to both professional
                          and personal spaces. From automating industrial
                          operations to creating smarter, more efficient homes,
                          we offer cutting-edge solutions that enhance
                          functionality, safety, and comfort.
                        </p>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Industrial Solutions
                        </div>

                        <p className="gray-point pb-5">
                          Empowering businesses with advanced technology to
                          improve productivity and operational efficiency:
                        </p>

                        <div className="pb-5">
                          {[
                            {
                              title: "Automation and Control Systems",
                              points: [
                                "Tailored automation solutions to optimize manufacturing, assembly lines, and industrial processes.",
                                "Real-time monitoring and control for seamless operations.",
                              ],
                            },
                            {
                              title: "Energy Management",
                              points: [
                                "Smart energy solutions to reduce consumption and operational costs.",
                                "Renewable energy integration, including solar and wind power systems.",
                              ],
                            },
                            {
                              title: "Industrial IoT (IIoT)",
                              points: [
                                "Leverage IoT devices to connect machinery, sensors, and data analytics platforms.",
                                "Enable predictive maintenance and real-time performance tracking.",
                              ],
                            },
                            {
                              title: "Safety and Security Systems",
                              points: [
                                "Robust surveillance, access control, and fire safety solutions.",
                                "Ensure employee safety and protect assets in high-risk industrial environments.",
                              ],
                            },
                            {
                              title: "Custom Equipment Design",
                              points: [
                                "Bespoke hardware and software solutions tailored to specific industrial requirements.",
                                "Scalable systems to support business growth.",
                              ],
                            },
                          ].map((item, index) => (
                            <ServicesOLUL
                              key={index}
                              index={index + 1}
                              item={item}
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Home Solutions
                        </div>

                        <p className="gray-point pb-5">
                          Transforming homes into smart, connected, and
                          sustainable living spaces:
                        </p>

                        <div className="pb-5">
                          {[
                            {
                              title: "Smart Home Automation",
                              points: [
                                "Control lighting, appliances, and climate systems with a single interface or via voice commands.",
                                "Enhance convenience and energy efficiency through automation.",
                              ],
                            },
                            {
                              title: "Home Security Systems",
                              points: [
                                "Advanced CCTV, intrusion alarms, and smart locks for comprehensive home security.",
                                "Remote monitoring and notifications for added peace of mind.",
                              ],
                            },
                            {
                              title: "Energy-Efficient Appliances",
                              points: [
                                "Eco-friendly appliances designed to reduce energy consumption without compromising performance.",
                                "Integration with renewable energy sources for sustainable living.",
                              ],
                            },
                            {
                              title: "Smart Lighting Solutions",
                              points: [
                                "Intelligent lighting systems with customizable settings for ambiance and energy savings.",
                                "Motion-sensitive and remote-controlled options available.",
                              ],
                            },
                            {
                              title: "Home Entertainment Systems",
                              points: [
                                "Cutting-edge audio and video systems for immersive entertainment.",
                                "Integration with smart devices for a connected experience.",
                              ],
                            },
                            {
                              title: "Smart Climate Control",
                              points: [
                                "Automated thermostats and climate control systems for optimal comfort.",
                                "Energy-saving features tailored to your preferences and schedule",
                              ],
                            },
                          ].map((item, index) => (
                            <ServicesOLUL
                              key={index}
                              index={index + 1}
                              item={item}
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Key Benefits
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              level: "Enhanced Efficiency",
                              content:
                                "Streamline operations in industrial spaces and optimize energy use at home.",
                            },
                            {
                              level: "Improved Safety",
                              content:
                                "Advanced security measures for both industrial facilities and residential spaces.",
                            },
                            {
                              level: "Scalability",
                              content:
                                "Solutions designed to grow and evolve with your business or home needs.",
                            },
                            {
                              level: "Cost Savings",
                              content:
                                "Energy-efficient technologies that reduce operational and utility costs.",
                            },
                            {
                              level: "Customization",
                              content:
                                "Tailored solutions to meet unique industry or personal lifestyle requirements.",
                            },
                          ].map((item, index) => (
                            <LevelingContent
                              key={index}
                              index={index + 1}
                              item={item}
                              type="ul"
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Industries We Serve
                        </div>

                        <div className="pl-2 pb-5">
                          <div className="flex gap-1 gray-point">
                            <span className="h-[22px] flex items-center pr-1">
                              <span className="h-[4px] w-[4px] rounded-full bg-[#858e96]"></span>
                            </span>
                            Manufacturing
                          </div>

                          <div className="flex gap-1 gray-point">
                            <span className="h-[22px] flex items-center pr-1">
                              <span className="h-[4px] w-[4px] rounded-full bg-[#858e96]"></span>
                            </span>
                            Construction
                          </div>

                          <div className="flex gap-1 gray-point">
                            <span className="h-[22px] flex items-center pr-1">
                              <span className="h-[4px] w-[4px] rounded-full bg-[#858e96]"></span>
                            </span>
                            Energy and Utilities
                          </div>

                          <div className="flex gap-1 gray-point">
                            <span className="h-[22px] flex items-center pr-1">
                              <span className="h-[4px] w-[4px] rounded-full bg-[#858e96]"></span>
                            </span>
                            Healthcare
                          </div>

                          <div className="flex gap-1 gray-point">
                            <span className="h-[22px] flex items-center pr-1">
                              <span className="h-[4px] w-[4px] rounded-full bg-[#858e96]"></span>
                            </span>
                            Residential Communities
                          </div>
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Why Choose Us?
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              level: "Comprehensive Expertise",
                              content: [
                                "In-depth experience in delivering solutions across industrial and home sectors.",
                              ],
                            },
                            {
                              level: "Future-Ready Technology",
                              content: [
                                "Scalable and sustainable systems designed for the future.",
                              ],
                            },
                            {
                              level: "Tailored Support",
                              content: [
                                "From design to deployment and maintenance, we ensure smooth implementation.",
                              ],
                            },
                            {
                              level: "Commitment to Quality",
                              content: [
                                "Solutions built with the highest standards of reliability and durability.",
                              ],
                            },
                          ].map((item, index) => (
                            <LevelingContent
                              key={index}
                              index={index + 1}
                              item={item}
                              type="ol"
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Let’s Transform Your Space
                        </div>

                        <p className="gray-point pb-5">
                          Whether you're optimizing industrial operations or
                          creating a smart home, our Industrial and Home
                          Solutions are your gateway to a more efficient, safe,
                          and connected future.
                        </p>

                        <div
                          className="gray-point"
                          style={{ color: "#195CA5", fontWeight: "600" }}
                        >
                          Contact us today to discuss your unique requirements
                          and discover the perfect solution for your needs.
                        </div>
                      </div>
                    ) : selectedService === 14 ? (
                      <div>
                        <p className="gray-point pb-5">
                          In the digital age, education transcends traditional
                          boundaries, driven by innovative platforms that make
                          learning accessible, engaging, and efficient. Our
                          Education Solutions harness cutting-edge technology to
                          deliver immersive and tailored learning experiences
                          through apps, websites, and web applications. These
                          solutions empower educational institutions, educators,
                          and students to thrive in a rapidly evolving learning
                          environment.
                        </p>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Our Comprehensive Education Solutions
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              title: "Mobile Learning Apps",
                              points: [
                                "Custom Educational Apps: Seamlessly designed for iOS and Android, offering students on-the-go access to courses, quizzes, and assignments.",
                                "Interactive Features: Push notifications for reminders, gamified learning modules, and real-time updates.",
                                "Offline Access: Downloadable content ensures learning continues without internet connectivity.",
                              ],
                            },
                            {
                              title: "Educational Websites",
                              points: [
                                "Responsive Design: User-friendly interfaces optimized for desktops, tablets, and mobile devices.",
                                "Content Management: Robust platforms for hosting digital textbooks, multimedia lessons, and educational resources.",
                                "Multi-Language Support: Breaking language barriers to make education more inclusive.",
                              ],
                            },
                            {
                              title: "Web Applications",
                              points: [
                                "Interactive Dashboards: Real-time progress tracking, personalized recommendations, and performance analytics.",
                                "Integrated Communication Tools: Chat, forums, and video conferencing for collaborative learning.",
                                "Cross-Platform Functionality: Seamlessly accessible from any device with an internet connection.",
                              ],
                            },
                            {
                              title: "Learning Management Systems (LMS)",
                              points: [
                                "Customizable Portals: Tailored to align with your institution’s needs, streamlining course delivery and student management.",
                                "Integration Capabilities: Easily connect with apps, websites, and existing tools for a cohesive digital ecosystem.",
                                "Advanced Reporting: Data-driven insights into learner progress and institutional performance.",
                              ],
                            },
                            {
                              title: "E-Learning Platforms",
                              points: [
                                "Multimedia-Rich Content: Videos, animations, and interactive lessons enhance engagement.",
                                "Adaptive Learning Paths: AI-powered personalization to cater to individual student needs.",
                                "Assessment Tools: Online quizzes, assignments, and secure proctoring for examinations.",
                              ],
                            },
                            {
                              title: "Hybrid Learning Support",
                              points: [
                                "Blended Learning: Combining traditional classroom methods with digital tools for a seamless teaching experience.",
                                "Collaboration Features: Real-time collaboration through document sharing, whiteboards, and group chats.",
                              ],
                            },
                            {
                              title: "STEM & Skill-Based Learning",
                              points: [
                                "Apps and Portals for STEM: Encouraging innovation with interactive modules and virtual labs.",
                                "Skill Development: Tools for coding, robotics, and vocational training programs.",
                              ],
                            },
                          ].map((item, index) => (
                            <ServicesOLUL
                              key={index}
                              index={index + 1}
                              item={item}
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Key Features Across Platforms
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              level: "Accessibility",
                              content:
                                "Anytime, anywhere learning through mobile apps, websites, and web apps.",
                            },
                            {
                              level: "Interactive Design",
                              content:
                                "Engaging user interfaces to ensure intuitive navigation and high user satisfaction.",
                            },
                            {
                              level: "Security",
                              content:
                                "Ensuring data privacy and secure transactions with the latest security protocols.",
                            },
                            {
                              level: "Scalability",
                              content:
                                "Solutions that grow with your institution, accommodating increasing numbers of users.",
                            },
                            {
                              level: "Custom Branding",
                              content:
                                "Maintain a consistent institutional identity across all platforms.",
                            },
                          ].map((item, index) => (
                            <LevelingContent
                              key={index}
                              index={index + 1}
                              item={item}
                              type="ul"
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Benefits of Our Solutions
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              level: "Enhanced Engagement",
                              content:
                                "Gamification and interactive elements captivate learners across platforms.",
                            },
                            {
                              level: "Seamless Integration",
                              content:
                                "Unified experience across apps, websites, and web apps for effortless transition between devices.",
                            },
                            {
                              level: "Cost Efficiency",
                              content:
                                "Centralized management reduces operational costs and administrative overhead.",
                            },
                            {
                              level: "Real-Time Updates",
                              content:
                                "Sync content and data seamlessly across all devices.",
                            },
                            {
                              level: "Increased Reach",
                              content:
                                "Mobile-first design ensures accessibility even in remote areas.",
                            },
                          ].map((item, index) => (
                            <LevelingContent
                              key={index}
                              index={index + 1}
                              item={item}
                              type="ul"
                            />
                          ))}
                        </div>
                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Who Can Benefit?
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              level: "Educational Institutions",
                              content:
                                "Schools, colleges, and universities looking to modernize their approach.",
                            },
                            {
                              level: "Corporate Training",
                              content:
                                "Businesses requiring advanced tools for employee training and development.",
                            },
                            {
                              level: "Skill Development Centers",
                              content:
                                "Organizations focused on vocational and technical education.",
                            },
                            {
                              level: "Governments",
                              content:
                                "Programs aimed at mass education and e-learning initiatives.",
                            },
                          ].map((item, index) => (
                            <LevelingContent
                              key={index}
                              index={index + 1}
                              item={item}
                              type="ul"
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Why Choose Us?
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              level: "Platform Diversity",
                              content:
                                "Expertise in creating tailored apps, websites, and web applications.",
                            },
                            {
                              level: "User-Centric Design",
                              content:
                                "Prioritizing usability to enhance learner and educator experiences.",
                            },
                            {
                              level: "Innovation-Driven",
                              content:
                                "Leveraging the latest technologies for impactful educational solutions.",
                            },
                            {
                              level: "Dedicated Support",
                              content:
                                "Comprehensive maintenance to ensure uninterrupted learning.",
                            },
                          ].map((item, index) => (
                            <LevelingContent
                              key={index}
                              index={index + 1}
                              item={item}
                              type="ol"
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Transform Education with Us
                        </div>

                        <p className="gray-point pb-5">
                          The world of education is evolving, and so should your
                          approach. Whether it’s creating smart classrooms,
                          managing student data, or implementing cutting-edge
                          learning tools, our solutions are designed to
                          transform education into a seamless, effective, and
                          enriching experience.
                        </p>

                        <div
                          className="gray-point"
                          style={{ color: "#195CA5", fontWeight: "600" }}
                        >
                          Contact us today to explore how our solutions can
                          empower your learners and educators to achieve
                          unparalleled success.
                        </div>
                      </div>
                    ) : selectedService === 15 ? (
                      <div>
                        <p className="gray-point pb-5">
                          SmartMatrix is dedicated to revolutionizing the
                          healthcare industry through innovative and
                          comprehensive Healthcare Solutions. By leveraging
                          cutting-edge technologies, our solutions aim to
                          enhance patient care, improve operational efficiency,
                          and drive positive outcomes across the healthcare
                          ecosystem.
                        </p>

                        <p className="gray-point pb-5">
                          <span style={{ color: "#000000", fontWeight: "600" }}>
                            Telemedicine and Remote Patient Monitoring:
                          </span>{" "}
                          Telemedicine has become an integral part of modern
                          healthcare, providing accessible and efficient care
                          remotely. SmartMatrix's Telemedicine Solutions
                          facilitate virtual consultations, enabling patients to
                          connect with healthcare professionals from the comfort
                          of their homes. Additionally, our Remote Patient
                          Monitoring solutions leverage wearable devices and
                          connected sensors to track vital signs and health
                          metrics in real-time, allowing healthcare providers to
                          monitor and intervene proactively.
                        </p>

                        <p className="gray-point pb-5">
                          <span style={{ color: "#000000", fontWeight: "600" }}>
                            Electronic Health Records (EHR) and Health
                            Information Systems:
                          </span>{" "}
                          Our EHR solutions streamline the management of patient
                          records and health information. SmartMatrix offers
                          robust and secure platforms that allow healthcare
                          providers to digitize patient data, ensuring its
                          accessibility, accuracy, and confidentiality.
                          Integration with Health Information Systems enhances
                          interoperability and collaboration among healthcare
                          professionals, leading to more informed
                          decision-making.
                        </p>

                        <p className="gray-point pb-5">
                          <span style={{ color: "#000000", fontWeight: "600" }}>
                            Hospital Management Systems:
                          </span>{" "}
                          Efficient hospital management is critical for
                          providing quality patient care. SmartMatrix's Hospital
                          Management Systems automate administrative tasks,
                          including appointment scheduling, billing, and
                          inventory management. These systems optimize
                          workflows, reduce paperwork, and enhance the overall
                          efficiency of healthcare institutions.
                        </p>

                        <p className="gray-point pb-5">
                          <span style={{ color: "#000000", fontWeight: "600" }}>
                            Medical Imaging Solutions:
                          </span>{" "}
                          SmartMatrix's Medical Imaging Solutions leverage
                          advanced technologies such as Picture Archiving and
                          Communication Systems (PACS) and Radiology Information
                          Systems (RIS) to enhance the management and
                          interpretation of medical images. These solutions
                          improve diagnostic accuracy, facilitate image sharing,
                          and contribute to better-informed treatment decisions.
                        </p>

                        <p className="gray-point pb-5">
                          <span style={{ color: "#000000", fontWeight: "600" }}>
                            Pharmacy Management Systems:
                          </span>{" "}
                          In the complex landscape of healthcare, efficient
                          pharmacy management is essential. SmartMatrix provides
                          Pharmacy Management Systems that automate prescription
                          management, inventory control, and billing processes.
                          These systems enhance accuracy, reduce errors, and
                          improve the overall efficiency of pharmacy operations.
                        </p>

                        <p className="gray-point pb-5">
                          <span style={{ color: "#000000", fontWeight: "600" }}>
                            Health Analytics and Business Intelligence:
                          </span>{" "}
                          Data-driven insights are invaluable for healthcare
                          organizations. SmartMatrix's Health Analytics and
                          Business Intelligence solutions enable healthcare
                          providers to analyze large datasets, derive actionable
                          insights, and make informed decisions. These tools
                          contribute to improving patient outcomes, optimizing
                          resource allocation, and identifying trends and
                          patterns.
                        </p>

                        <p className="gray-point pb-5">
                          <span style={{ color: "#000000", fontWeight: "600" }}>
                            Patient Engagement and Wellness Solutions:
                          </span>{" "}
                          Empowering patients to actively participate in their
                          healthcare journey is a key focus of SmartMatrix. Our
                          Patient Engagement and Wellness Solutions include
                          mobile applications and platforms that provide
                          patients with access to educational resources,
                          appointment reminders, and personalized wellness
                          plans. By fostering patient engagement, these
                          solutions contribute to better health outcomes.
                        </p>

                        <p className="gray-point pb-5">
                          <span style={{ color: "#000000", fontWeight: "600" }}>
                            Cybersecurity Solutions for Healthcare:
                          </span>{" "}
                          Recognizing the importance of data security in
                          healthcare, SmartMatrix offers robust cybersecurity
                          solutions. These measures safeguard patient
                          information, ensure compliance with data protection
                          regulations, and protect healthcare institutions from
                          cyber threats.
                        </p>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Benefits of Our Healthcare Solutions
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              level: "Improved Patient Care",
                              content:
                                "Streamlined processes and innovative tools to enhance patient outcomes.",
                            },
                            {
                              level: "Operational Efficiency",
                              content:
                                "Automation and integration to reduce administrative burdens.",
                            },
                            {
                              level: "Enhanced Accessibility",
                              content:
                                "Telemedicine and remote monitoring solutions for broader healthcare reach.",
                            },
                            {
                              level: "Data-Driven Insights",
                              content:
                                "Analytics to make informed decisions and improve treatment efficacy.",
                            },
                            {
                              level: "Cost-Effective Solutions",
                              content:
                                "Reduce operational costs without compromising quality.",
                            },
                            {
                              level: "Regulatory Compliance",
                              content:
                                "Ensure adherence to industry standards and data privacy regulations.",
                            },
                          ].map((item, index) => (
                            <LevelingContent
                              key={index}
                              index={index + 1}
                              item={item}
                              type="ul"
                            />
                          ))}
                        </div>
                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Who Can Benefit?
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              level: "Hospitals and Clinics",
                              content:
                                "Comprehensive management systems and advanced diagnostic tools.",
                            },
                            {
                              level: "Specialty Care Providers",
                              content:
                                "Tailored solutions for oncology, cardiology, and other specialties.",
                            },
                            {
                              level: "Pharmacies",
                              content:
                                "Streamlined operations with inventory and prescription management tools.",
                            },
                            {
                              level: "Health Tech Startups",
                              content:
                                "Support for innovative healthcare applications and IoT devices.",
                            },
                            {
                              level: "Government and NGOs",
                              content:
                                "Scalable solutions for public health programs and initiatives.",
                            },
                          ].map((item, index) => (
                            <LevelingContent
                              key={index}
                              index={index + 1}
                              item={item}
                              type="ul"
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Why Choose Us?
                        </div>

                        <div className="pb-5">
                          {[
                            {
                              level: "Customizable Solutions",
                              content:
                                "Designed to meet the specific needs of your healthcare organization.",
                            },
                            {
                              level: "Innovative Technology",
                              content:
                                "Leveraging AI, IoT, and cloud for cutting-edge healthcare delivery.",
                            },
                            {
                              level: "End-to-End Support",
                              content:
                                "From implementation to maintenance, we ensure seamless service.",
                            },
                            {
                              level: "Scalability",
                              content:
                                "Solutions that grow with your organization, ensuring long-term relevance.",
                            },
                            {
                              level: "Security and Compliance",
                              content:
                                "Strict adherence to global healthcare standards and data security protocols.",
                            },
                          ].map((item, index) => (
                            <LevelingContent
                              key={index}
                              index={index + 1}
                              item={item}
                              type="ol"
                            />
                          ))}
                        </div>

                        <div
                          className="service-paragraph-title pb-5"
                          style={{ color: "#000000" }}
                        >
                          Transform Healthcare with Us
                        </div>

                        <p className="gray-point pb-5">
                          As the healthcare industry embraces digital
                          transformation, our solutions are at the forefront of
                          innovation, ensuring that providers and patients alike
                          benefit from advancements in technology. Whether
                          you’re looking to optimize your operations, enhance
                          patient engagement, or implement cutting-edge tools,
                          our solutions are designed to drive measurable impact.
                        </p>

                        <div
                          className="gray-point"
                          style={{ color: "#195CA5", fontWeight: "600" }}
                        >
                          Contact us today to explore how our Healthcare
                          Solutions can revolutionize your care delivery and
                          operational excellence.
                        </div>
                      </div>
                    ) : selectedService === 16 ? (
                      <div>
                        <p className="gray-point pb-5">
                          Our Smart Commodities division is at the heart of
                          revolutionizing essential goods and resources. We are
                          dedicated to providing high-quality commodities that
                          cater to diverse industries and consumer needs.
                          Whether it's agricultural produce, energy resources,
                          or innovative material solutions, Smart Commodities
                          stands out for its unwavering commitment to
                          excellence, reliability, and sustainability.
                        </p>

                        <p className="gray-point pb-5">
                          By leveraging cutting-edge technologies, strategic
                          partnerships, and a deep understanding of market
                          dynamics, Smart Commodities ensures efficient supply
                          chain management, optimized delivery, and unparalleled
                          quality. Our goal is not just to meet the demand but
                          to redefine it—creating smarter, more sustainable
                          solutions for the global communit
                        </p>

                        <div
                          className="gray-point"
                          style={{ color: "#195CA5", fontWeight: "600" }}
                        >
                          Contact us today to explore how our Smart Commodity
                          Solutions can drive efficiency, transparency, and
                          growth for your business.
                        </div>
                      </div>
                    ) : (
                      <div className="service-description">
                        {parse(data.description)}
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <></>
              )
            )}
            {selectedService === 16 ? (
              <a
                type="button"
                className="mt-9 DiscoverMoreButton"
                href="https://www.smartmatrix.life/"
                target="_blank"
                rel="noreferrer"
              >
                Get Solution
              </a>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <ReuseableFooterBanner
        bannerImage={bannerImage}
        title="Need an IT Solution?"
        content="Got a project in mind? We’d love to hear about it. Take five minutes to fill out our project form so that we can get to know you and understand your project."
        buttonName="CONTACT US"
        url="/contact"
      />
      <Footer />
    </>
  );
}

export default Services;
