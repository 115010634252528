/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
import React from "react";
import { useNavigate } from "react-router";
import gallaryImg1 from "../../assets/Footer/Rectangle 31.webp";
import gallaryImg2 from "../../assets/Footer/Rectangle 32.webp";
import gallaryImg3 from "../../assets/Footer/Rectangle 33.webp";
import gallaryImg4 from "../../assets/Footer/Rectangle 34.webp";
import gallaryImg5 from "../../assets/Footer/Rectangle 35.webp";
import gallaryImg6 from "../../assets/Footer/Rectangle 36.webp";
import facebook from "../../assets/Footer/fb.png";
import Logo from "../../assets/Footer/footer-logo.png";
import linkdin from "../../assets/Footer/lnkd.png";
import "./Footer.css";

function Footer() {
  const navigate = useNavigate();
  function getCurrentYear() {
    const currentYear = new Date().getFullYear();
    return currentYear;
  }

  return (
    <div className="footer-section">
      <div className="container">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-5 py-24">
          <div>
            <img src={Logo} alt="logo" className="h-[65px] w-[190px]" />
            <p className="my-4 footer-text">
              SmartMatrix delivers innovative, tech-driven solutions that
              empower businesses, streamline operations, and foster growth,
              turning challenges into opportunities for sustainable success
              worldwide.
            </p>
            <div className="flex flex-row gap-4">
              <a
                href="https://www.facebook.com/people/SMARTMATRIX-PTE-LTD/100063454840485/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={facebook}
                  alt="facebook"
                  className="h-[50px] w-[50px]"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/smartmatrix-pte-ltd/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={linkdin}
                  alt="linkdin"
                  className="h-[50px] w-[50px]"
                />
              </a>
            </div>
          </div>
          <div>
            <p className="footer-title">Services & Solution</p>
            <div className="relative">
              <div className="my-4 border-[#858E96] w-full border " />
              <div className="absolute top-0 border border-[#2792D0] w-[40px]" />
            </div>
            <p
              className="footer-text cursor-pointer"
              onClick={() => navigate("/services/9")}
            >
              Smart Cards
            </p>
            <p
              className="pt-2 footer-text cursor-pointer"
              onClick={() => navigate("/services/10")}
            >
              IT Hardware
            </p>
            <p
              className="pt-2 footer-text cursor-pointer"
              onClick={() => navigate("/services/11")}
            >
              Customised Electronic
            </p>
            <p
              className="pt-2 footer-text cursor-pointer"
              onClick={() => navigate("/services/11")}
            >
              Devices
            </p>
            <p
              className="pt-2 footer-text cursor-pointer"
              onClick={() => navigate("/services/1")}
            >
              Software Solution
            </p>
          </div>
          <div>
            <p className="footer-title">Explore</p>
            <div className="relative">
              <div className="my-4 border-[#858E96] w-full border " />
              <div className="absolute top-0 border border-[#2792D0] w-[40px]" />
            </div>
            <p
              className="footer-text cursor-pointer"
              onClick={() => navigate("/")}
            >
              Home
            </p>
            <p
              className="pt-2 footer-text cursor-pointer"
              onClick={() => navigate("/about")}
            >
              About Us
            </p>
            <p
              className="pt-2 footer-text cursor-pointer"
              onClick={() => navigate("/career")}
            >
              Career
            </p>
            <p
              className="pt-2 footer-text cursor-pointer"
              onClick={() => navigate("/contact")}
            >
              Contact
            </p>
          </div>
          <div>
            <p className="footer-title">Gallery</p>
            <div className="relative">
              <div className="my-4 border-[#858E96] w-full border " />
              <div className="absolute top-0 border border-[#2792D0] w-[40px]" />
            </div>
            <div className="flex flex-wrap gap-3 w-full">
              <img
                src={gallaryImg1}
                alt="gallaryImg1"
                className="h-[75px] w-[75px] object-cover"
              />
              <img
                src={gallaryImg2}
                alt="gallaryImg2"
                className="h-[75px] w-[75px] object-cover"
              />
              <img
                src={gallaryImg3}
                alt="gallaryImg3"
                className="h-[75px] w-[75px] object-cover"
              />
              <img
                src={gallaryImg4}
                alt="gallaryImg4"
                className="h-[75px] w-[75px] object-cover"
              />
              <img
                src={gallaryImg5}
                alt="gallaryImg5"
                className="h-[75px] w-[75px] object-cover"
              />
              <img
                src={gallaryImg6}
                alt="gallaryImg6"
                className="h-[75px] w-[75px] object-cover"
              />
            </div>
          </div>
        </div>
        <div className="bg-[#2C2E33] py-8 text-[#858E96]">
          <p className="text-center">
            © {getCurrentYear()} SMARTMATRIX PTE LTD. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
