/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React from "react";
import Slider from "react-slick";
import {
  servicesWeAreOfferingCardDataColumn1,
  servicesWeAreOfferingCardDataColumn2,
} from "../../../store/store";
import "./ServicesWeAreOffering.css";
import ServicesWeAreOfferingCard from "./ServicesWeAreOfferingCard";

function ServicesWeAreOffering() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200, // 600px আগ পর্যন্ত 2টা দেখাবে
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 992, // 600px আগ পর্যন্ত 2টা দেখাবে
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768, // 299px আগ পর্যন্ত একটা দেখাবে
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="servicesWeAreOfferingBackgroudImage py-[60px] sectionContentMargin1 relative overflow-hidden">
      <div className="container">
        <div className="flex justify-center">
          <div className="flex flex-col items-center justify-center max-w-[560px]">
            <div className="flex items-center">
              <div className="servicesWeAreOfferingText">
                SOLUTION & SERVICES WE’RE OFFERING
              </div>
              <div className="bg-[#D4D4D4] w-[35px] h-[1.5px] ml-[10px]" />
            </div>
            <div className="servicesWeAreOfferingTitle text-center mt-[20px]">
              Leading Business Transformation with Innovative Technology
            </div>
          </div>
        </div>

        {/* <div className="grid grid-flow-row lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-5 mt-[60px]"> */}
        {/* <div className="grid grid-flow-row grid-cols-4  gap-5 mt-[60px]">
          {servicesWeAreOfferingCardData.map((cardDetails) => <ServicesWeAreOfferingCard key={cardDetails.id} cardDetails={cardDetails} />)}
        </div> */}

        <div className="mt-[60px]">
          <div>
            <Slider {...settings}>
              {servicesWeAreOfferingCardDataColumn1.map(
                (cardDetails, index) => (
                  <div key={index}>
                    <ServicesWeAreOfferingCard
                      cardDetails={cardDetails}
                      url={`/services/${cardDetails.serviceId}`}
                    />
                  </div>
                )
              )}
            </Slider>
          </div>

          <div className="mt-[20px]">
            <Slider {...settings}>
              {servicesWeAreOfferingCardDataColumn2.map(
                (cardDetails, index) => (
                  <div key={index} className="">
                    <ServicesWeAreOfferingCard
                      cardDetails={cardDetails}
                      url={`/services/${cardDetails.serviceId}`}
                    />
                  </div>
                )
              )}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesWeAreOffering;
