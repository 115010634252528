import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./RecentlyCompletedWorkSliderWithSwiper.css";
import { Navigation } from "swiper/modules";
import RecentlyCompletedWorkCard from "../RecentlyCompletedWorkCard";

const RecentlyCompletedWorkSliderWithSwiper = ({
  recentlyCompletedWorkCard,
}) => {
  return (
    <div className="relative mx-2">
      <Swiper
        className="mySwiper"
        modules={[Navigation]}
        spaceBetween={20}
        // slidesPerView={5}
        breakpoints={{
          340: {
            slidesPerView: 2,
          },
          640: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1060: {
            slidesPerView: 4,
          },
          1260: {
            slidesPerView: 4,
          },
        }}
        navigation={{
          prevEl: `.common-product-slider-prevBtn`,
          nextEl: `.common-product-slider-nextBtn`,
        }}
      >
        {recentlyCompletedWorkCard.map((cardDetails, index) => (
          <SwiperSlide key={index}>
            <RecentlyCompletedWorkCard cardDetails={cardDetails} />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="common-product-slider-prevBtn">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="23"
          height="24"
          viewBox="0 0 23 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.94741 12.707C7.76776 12.5194 7.66683 12.2651 7.66683 12C7.66683 11.7348 7.76776 11.4805 7.94741 11.293L13.3687 5.63598C13.4571 5.54047 13.5629 5.46428 13.6798 5.41188C13.7967 5.35947 13.9224 5.33188 14.0497 5.33073C14.1769 5.32957 14.3031 5.35487 14.4209 5.40516C14.5387 5.45544 14.6457 5.52969 14.7357 5.62358C14.8256 5.71747 14.8968 5.82913 14.945 5.95202C14.9932 6.07492 15.0174 6.2066 15.0163 6.33938C15.0152 6.47216 14.9888 6.60338 14.9386 6.72538C14.8883 6.84739 14.8153 6.95773 14.7238 7.04998L9.98004 12L14.7238 16.95C14.8984 17.1386 14.995 17.3912 14.9928 17.6534C14.9906 17.9156 14.8898 18.1664 14.7121 18.3518C14.5344 18.5372 14.2941 18.6424 14.0428 18.6447C13.7915 18.6469 13.5494 18.5461 13.3687 18.364L7.94741 12.707Z"
            fill="currentColor"
          />
        </svg>
      </div>
      <div className="common-product-slider-nextBtn">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="23"
          height="24"
          viewBox="0 0 23 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.0526 11.293C15.2322 11.4806 15.3332 11.7349 15.3332 12C15.3332 12.2652 15.2322 12.5195 15.0526 12.707L9.63129 18.364C9.54289 18.4595 9.43714 18.5357 9.32022 18.5881C9.2033 18.6405 9.07755 18.6681 8.9503 18.6693C8.82306 18.6704 8.69686 18.6451 8.57909 18.5948C8.46131 18.5446 8.35431 18.4703 8.26433 18.3764C8.17435 18.2825 8.10319 18.1709 8.05501 18.048C8.00682 17.9251 7.98257 17.7934 7.98368 17.6606C7.98478 17.5278 8.01122 17.3966 8.06145 17.2746C8.11167 17.1526 8.18468 17.0423 8.27621 16.95L13.02 12L8.27621 7.05002C8.10164 6.86142 8.00505 6.60882 8.00723 6.34662C8.00941 6.08442 8.1102 5.83361 8.28788 5.6482C8.46557 5.4628 8.70593 5.35763 8.9572 5.35535C9.20847 5.35307 9.45055 5.45386 9.63129 5.63602L15.0526 11.293Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
  );
};

export default RecentlyCompletedWorkSliderWithSwiper;
